import { axiosWithJWT } from "../config/axiosConfig";

async function updateBrand(id, data) {
    try {
        const response = await axiosWithJWT.put(`/api/brands/${id}`, data);
        return response;
    } catch (error) {
        throw error;
    }
}

async function getBrand() {
    try {
        const response = await axiosWithJWT.get(`/api/brands`);
        return response.data;
    } catch (error) {
        throw error;
    }
}
export { updateBrand, getBrand };
import React, { useEffect, useState } from "react";
import Content from "../../components/overview/content";
import { useDispatch, useSelector } from "react-redux";
import { registerInternship } from "../../api/userApi";
import { toast } from "react-toastify";
import {
  asyncCompanyInformation,
  asyncUserInformation,
} from "../../utils/asyncReduxUtils";
import { useTranslation } from "react-i18next";
import { confirmAlert } from "../../components/react-confirm-alert/lib";

const SponsorPage = () => {
  const company = useSelector((state) => state.companyInformationReducer);
  const currentUser = useSelector((state) => state.userInformationReducer);
  const [registered, setRegistered] = useState(false);

  const [width, setWidth] = useState(window.innerWidth);
  const dispath = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    setRegistered(
      currentUser.registerInternships.filter(
        (item) => item.company.id === company.id
      ).length > 0
    );
  }, [currentUser, company]);

  const handleRegisterInternship = () => {
    confirmAlert({
      title: "",
      message: t("notify.are_you_sure_to_register_this_internship"),
      buttons: [
        {
          label: t("notify.yes"),
          onClick: () => {
            registerInternship(company.id)
              .then(() => {
                asyncCompanyInformation(dispath, company.id);
                asyncUserInformation(dispath);
                toast.success(t("notify.register_success"), {});
              })
              .catch((error) => {
                toast.error(error.response.data.error.message, {});
              });
          },
        },
        {
          label: t("notify.no"),
          onClick: () => {},
        },
      ],
    });
  };

  useEffect(() => {
    window.addEventListener("resize", () => {
      if (window.innerWidth < 1024) {
        setWidth(1024);
      } else {
        setWidth(window.innerWidth);
      }
    });
  });

  return (
    <div className="w-full h-fit min-h-screen overflow-auto flex flex-col justify-start items-center gap-10 py-20">
      <div
        style={{
          width: company.width * 0.1 * width,
          height: company.height * 0.1 * width,
        }}
        className="w-fit h-fit mx-10 p-5 shadow-lg shadow-white-tertiary rounded-md flex justify-center items-center border-t-[2px] border-gray-50"
      >
        <img
          src={
            company.image
              ? company.image.url
              : 'no_image.png'
          }
          alt=""
          className="w-full max-w-[500px] h-full object-cover"
        />
      </div>
      <div className="lg:w-[70%] w-full h-fit lg:shadow-lg shadow-none shadow-white-tertiary rounded-md border-t-[2px] border-gray-50">
        <Content />
        <div className="w-full h-fit flex flex-row justify-end items-center gap-3 p-3">
          {!company.acceptedIntern && (
            <span className="w-fit h-fit py-3 px-5 bg-[#707070] cursor-not-allowed rounded-md shadow-md text-white-primary">
              {t("overview.can_not_register")}
            </span>
          )}

          {company.acceptedIntern &&
            currentUser.role === "Student" &&
            registered && (
              <span className="w-fit h-fit py-3 px-5 bg-[#707070] cursor-not-allowed rounded-md shadow-md text-white-primary">
                {t("overview.registered")}
              </span>
            )}

          {company.acceptedIntern &&
            currentUser.role === "Student" &&
            !registered && (
              <span
                className="w-fit h-fit py-3 px-5 bg-primary hover:bg-primary-hover cursor-pointer rounded-md shadow-md text-white-primary"
                onClick={() => handleRegisterInternship()}
              >
                {t("overview.register_internship")}
              </span>
            )}
        </div>
      </div>
    </div>
  );
};

export default SponsorPage;

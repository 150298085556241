import React, { useEffect, useState } from "react";
import { IoIosCloseCircle } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { hideOverlay } from "../../store/common/pageOverlaySlice";
import Content from "./content";
import { registerInternship } from "../../api/userApi";
import { toast } from "react-toastify";
import { asyncUserInformation } from "../../utils/asyncReduxUtils";
import { useTranslation } from "react-i18next";
import { confirmAlert } from "../react-confirm-alert/lib";

const CompanyOverview = () => {
    const dispartch = useDispatch();
    const { t } = useTranslation();

    const [registered, setRegistered] = useState(false);

    const company = useSelector((state) => state.companyInformationReducer);
    const currentUser = useSelector((state) => state.userInformationReducer);

    useEffect(() => {
        setRegistered(
            currentUser.registerInternships.filter((item) => item.company.id === company.id)
                .length > 0
        );
    }, [currentUser, company]);

    const handleRegisterInternship = () => {
        confirmAlert({
            title: "",
            message: t("notify.are_you_sure_to_register_this_internship"),
            buttons: [
                {
                    label: t("notify.yes"),
                    onClick: () => {
                        registerInternship(company.id)
                            .then(() => {
                                asyncUserInformation(dispartch);
                                toast.success(t("notify.register_success"), {});
                            })
                            .catch((error) => {
                                toast.error(error.response.data.error.message, {});
                            });
                    },
                },
                {
                    label: t("notify.no"),
                    onClick: () => { },
                },
            ],
        });
    };

    return (
        <div className="w-full h-[calc(100vh-90px)] bg-white-primary flex flex-col justify-center items-center rounded-md shadow-md shadow-white-tertiary text-black-secondary overflow-auto">
            <div
                className="absolute right-2 top-2 scale-150 cursor-pointer"
                onClick={() => dispartch(hideOverlay())}>
                <IoIosCloseCircle />
            </div>
            <div className="w-full h-fit p-3 text-left">
                <span className="text-[18px] font-bold text-primary">
                    {t("overview.company_information")}
                </span>
            </div>
            <div className="w-full h-[1px] bg-white-secondary" />
            <Content />
            <div className="w-full h-[1px] bg-white-secondary" />
            <div className="w-full h-fit flex flex-row justify-end items-center gap-3 p-3">
                {!company.acceptedIntern && currentUser.role === "Student" && (
                    <span className="w-fit h-fit py-3 px-5 bg-[#707070] cursor-not-allowed rounded-md shadow-md text-white-primary">
                        {t("overview.can_not_register")}
                    </span>
                )}

                {company.acceptedIntern && currentUser.role === "Student" && registered && (
                    <span className="w-fit h-fit py-3 px-5 bg-[#707070] cursor-not-allowed rounded-md shadow-md text-white-primary">
                        {t("overview.registered")}
                    </span>
                )}

                {company.acceptedIntern && currentUser.role === "Student" && !registered && (
                    <span
                        className="w-fit h-fit py-3 px-5 bg-primary hover:bg-primary-hover cursor-pointer rounded-md shadow-md text-white-primary"
                        onClick={handleRegisterInternship}>
                        {t("overview.register_internship")}
                    </span>
                )}

                <span
                    className="w-fit h-fit py-3 px-5 rounded-md shadow-md bg-primary hover:bg-primary-hover cursor-pointer text-white-primary"
                    onClick={() => dispartch(hideOverlay())}>
                    {t("overview.close")}
                </span>
            </div>
        </div>
    );
};

export default CompanyOverview;

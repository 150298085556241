import React from "react";
import { Modal, Box, Button } from "@mui/material";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import "pdfjs-dist/web/pdf_viewer.css";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  height: "80%",
  overflow: "auto",
  borderRadius: "8px",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const FileModal = ({ open, handleClose, fileUrl, fileType }) => {
  const docs = [{ uri: fileUrl, fileType: fileType }];
  const isDocx = fileType === "application/msword";
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={modalStyle}>
        {isDocx ? (
          <iframe
            src={`https://docs.google.com/gview?url=${fileUrl}&embedded=true`}
            style={{ width: "100%", height: "100%" }}
            title="Document Viewer"
          />
        ) : (
          <DocViewer
            documents={docs}
            pluginRenderers={DocViewerRenderers}
            style={{ width: "100%", height: "100%" }}
          />
        )}
      </Box>
    </Modal>
  );
};

export default FileModal;

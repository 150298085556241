import { axiosWithJWT } from "../config/axiosConfig";

const removeFile = async (fileId) => {
    try {
        const response = await axiosWithJWT.delete(`/api/upload/files/${fileId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
}

export { removeFile };
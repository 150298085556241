import { Navigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie"; // Import thư viện js-cookie

const ProtectedRoute = ({ children, allowedRole }) => {
    const token = Cookies.get("jwt"); // Lấy token từ cookie

    if (token) {
        try {
            // Giải mã JWT để lấy role
            const decodedToken = jwtDecode(token);

            // So sánh role của user với allowedRole
            if (decodedToken.role === allowedRole) {
                return children; // Nếu role hợp lệ, render component con
            }
        } catch (error) {
            console.error("Invalid token", error);
        }
    }

    // Nếu không có token hoặc role không hợp lệ, điều hướng đến trang 404
    return <Navigate to="/404" />;
};

export default ProtectedRoute;

import { configureStore } from '@reduxjs/toolkit'
import pageOverlaySlice from './common/pageOverlaySlice'
import userInformationSlice from './common/userInformationSlice'
import companyInformationSlice from './common/companyInformationSlice'
import themeSlice from './common/themeSlice'

export const store = configureStore({
  reducer: {
    pageOverlayReducer: pageOverlaySlice,
    userInformationReducer: userInformationSlice,
    companyInformationReducer: companyInformationSlice,
    themeReducer: themeSlice,
  },
})
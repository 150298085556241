import React, { useEffect, useState } from "react";
import CKEditor5 from "../ckeditor";
import { useTranslation } from "react-i18next";
import FileUpload from "../upload/image";
import { useDispatch, useSelector } from "react-redux";
import { uploadFile } from "../../api/uploadFile";
import { updateCompany } from "../../api/companyApi";
import { toast } from "react-toastify";
import { isEmpty, isValidateEmail } from "../../utils/utils";
import { asyncUserInformation } from "../../utils/asyncReduxUtils";
import Cookies from 'js-cookie';
import { IoIosCloseCircle } from "react-icons/io";
import {
  createInternshipFile,
  removeInternshipFile,
} from "../../api/internshipFileApi";
import { FaTrashCan } from "react-icons/fa6";
import { removeFile } from "../../api/removeFile";
import { confirmAlert } from "../react-confirm-alert/lib";
import { jwtDecode } from "jwt-decode";

const Information = () => {
  // Get translation
  const { t } = useTranslation();

  // Get company information from Redux
  const companyInformation = useSelector(
    (state) => state.userInformationReducer.company
  );
  const dispatch = useDispatch();

  // State for company information
  const [companyShortname, setCompanyShortname] = useState(
    companyInformation.shortname || ""
  );
  const [companyFullname, setCompanyFullname] = useState(
    companyInformation.fullname || ""
  );
  const [companyAddress, setCompanyAddress] = useState(
    companyInformation.address || ""
  );
  const [companyPhoneNumber, setCompanyPhoneNumber] = useState(
    companyInformation.phoneNumber || ""
  );
  const [companyContactEmail, setCompanyContactEmail] = useState(
    companyInformation.contactEmail || ""
  );
  const [ckeditorData, setCkeditorData] = useState(
    companyInformation.work || ""
  );
  const [companyFiles, setCompanyFiles] = useState(
    companyInformation.internshipFiles || []
  );

  // State for uploaded image
  const [uploadedImage, setUploadedImage] = useState(null);

  // State for CKEditor data

  // State for files uploaded
  const [files, setFiles] = useState([]);

  // Handle change file when upload
  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    setFiles([...files, ...selectedFiles]);
  };

  // Handle remove file at index
  const removeFileUpload = (indexToRemove) => {
    const updatedFiles = files.filter((_, index) => index !== indexToRemove);
    setFiles(updatedFiles);
  };

  useEffect(() => {
    setCompanyFiles(companyInformation.internshipFiles || []);
  }, [companyInformation.internshipFiles]);

  // Handle remove file at index
  const removeFileUploaded = async (indexToRemove) => {
    const handleDelete = async () => {
      try {
        // Get file to remove
        const fileRemove = companyInformation.internshipFiles[indexToRemove];

        // Remove file from Media Library
        await removeFile(fileRemove.fileId);
        // Remove content Internship File
        await removeInternshipFile(fileRemove.id);

        // Async user information again and async update in Redux
        asyncUserInformation(dispatch);

        toast.success('"' + fileRemove.name + '"' + t("notify.delete_success"));
      } catch (error) {
        toast.error(error.response.data.error.message);
      }
    };

    confirmAlert({
      title: "",
      message: t("notify.are_you_sure_delete_this_file"),
      buttons: [
        {
          label: t("notify.yes"),
          onClick: () => handleDelete(),
        },
        {
          label: t("notify.no"),
          onClick: () => { },
        },
      ],
    });
  };

  // Handle change company information when click Save Changes
  const handleChangeCompanyInformation = async () => {
    // Check if fields are empty
    if (
      isEmpty(companyFullname) ||
      isEmpty(companyShortname) ||
      isEmpty(companyAddress) ||
      isEmpty(companyContactEmail) ||
      isEmpty(companyPhoneNumber)
    ) {
      toast.error(t("notify.empty_fields"));
      return;
    }

    // Check if phone number is invalid
    if (companyPhoneNumber.length < 10 || companyPhoneNumber.length > 11) {
      toast.error(t("notify.phone_number_invalid"));
      return;
    }

    // Check if email is valid
    if (!isValidateEmail(companyContactEmail)) {
      toast.error(t("notify.invalid_email"));
      return;
    }

    const updateCompanyInformation = async () => {
      try {
        // Update basic company information first
        await updateCompany(companyInformation.id, {
          shortname: companyShortname,
          fullname: companyFullname,
          address: companyAddress,
          phoneNumber: companyPhoneNumber,
          contactEmail: companyContactEmail,
          work: ckeditorData,
        });

        // Upload image if available
        if (uploadedImage) {
          await uploadFile(
            "api::company.company",
            companyInformation.id,
            "image",
            uploadedImage
          );
          setUploadedImage(null);
        }

        // Upload files if available
        if (files.length > 0) {
          let listFileUploadedId = [...companyFiles];
          const accountId = jwtDecode(Cookies.get(process.env.REACT_APP_JWT_NAME)).accountId || null;

          for (const file of files) {
            // Create internship file
            const internshipFile = await createInternshipFile(file, accountId);

            // Upload the file
            await uploadFile(
              "api::internship-file.internship-file",
              internshipFile.data.id,
              "file",
              file
            );

            // Collect uploaded file IDs
            listFileUploadedId.push(internshipFile.data.id);
          }

          await updateCompany(companyInformation.id, {
            internshipFiles: listFileUploadedId,
          });

          setFiles([]);
        }

        toast.success(t("notify.update_success"));

        // Update user information in Redux
        asyncUserInformation(dispatch);
      } catch (error) {
        // Handle error from any of the above actions
        toast.error(error.response.data.error.message);
      }
    };

    confirmAlert({
      title: "",
      message: t("notify.are_you_sure_you_want_to_update"),
      buttons: [
        {
          label: t("notify.yes"),
          onClick: () => updateCompanyInformation(),
        },
        {
          label: t("notify.no"),
          onClick: () => { },
        },
      ],
    });
  };

  return (
    <div className="w-full flex-grow flex flex-col justify-start items-start bg-black-tertiary p-3 overflow-auto text-sm">
      <div className="w-full h-fit bg-white-primary p-3 rounded-md shadow-md shadow-white-tertiary">
        <span className="text-lg font-semibold">
          {t("company.company_information")}
        </span>
        <div className="w-full h-fit flex xl:flex-row flex-col justify-between items-start px-3 pt-5 gap-7">
          <div className="xl:w-2/3 w-full h-full">
            <span>{t("company.company_shortname")}</span>
            <input
              type="text"
              tabIndex={1}
              placeholder={t("company.company_shortname")}
              className="mt-1 mb-3 w-full border border-white-secondary focus:border-white-tertiary outline-none py-[7px] px-4 rounded-md shadow-sm"
              value={companyShortname}
              onChange={(e) => setCompanyShortname(e.target.value)}
            />

            <span>{t("company.company_fullname")}</span>
            <input
              type="text"
              tabIndex={2}
              placeholder={t("company.company_fullname")}
              className="mt-1 mb-3 w-full border border-white-secondary focus:border-white-tertiary outline-none py-[7px] px-4 rounded-md shadow-sm"
              value={companyFullname}
              onChange={(e) => setCompanyFullname(e.target.value)}
            />

            <span>{t("company.company_address")}</span>
            <input
              type="text"
              tabIndex={3}
              placeholder={t("company.company_address")}
              className="mt-1 mb-3 w-full border border-white-secondary focus:border-white-tertiary outline-none py-[7px] px-4 rounded-md shadow-sm"
              value={companyAddress}
              onChange={(e) => setCompanyAddress(e.target.value)}
            />

            <span>{t("company.phone_number")}</span>
            <input
              type="text"
              tabIndex={4}
              placeholder={t("company.phone_number")}
              className="mt-1 mb-3 w-full border border-white-secondary focus:border-white-tertiary outline-none py-[7px] px-4 rounded-md shadow-sm"
              value={companyPhoneNumber}
              onChange={(e) => setCompanyPhoneNumber(e.target.value)}
            />

            <span>{t("company.email_contact")}</span>
            <input
              type="text"
              tabIndex={5}
              placeholder={t("company.email_contact")}
              className="mt-1 mb-3 w-full border border-white-secondary focus:border-white-tertiary outline-none py-[7px] px-4 rounded-md shadow-sm"
              value={companyContactEmail}
              onChange={(e) => setCompanyContactEmail(e.target.value)}
            />

            <span>{t("company.company_file")}</span>
            <div className="w-full h-fit flex flex-row flex-wrap gap-1 my-3">
              {companyFiles.map((file, index) => (
                <div
                  key={index}
                  className="text-white-primary bg-primary w-fit h-fit shadow-sm shadow-white-tertiary px-2 py-1 flex flex-row justify-start items-center gap-5 rounded-[4px] hover:bg-primary-hover"
                >
                  <a
                    href={file.url}
                    target="_blank"
                    className="hover:underline"
                  >
                    {file.name}
                  </a>
                  <div
                    className="scale-125 cursor-pointer text-white-primary"
                    onClick={() => removeFileUploaded(index)}
                  >
                    <FaTrashCan />
                  </div>
                </div>
              ))}
            </div>

            <div className="mt-2">
              <label
                htmlFor="file-upload"
                className="w-full h-fit py-2 flex justify-center items-center bg-primary hover:bg-primary-hover text-white font-semibold rounded-md shadow-md cursor-pointer"
              >
                {t("company.select_files")}
                <input
                  id="file-upload"
                  type="file"
                  accept=".pdf,.doc,.docx"
                  onChange={handleFileChange}
                  multiple
                  className="hidden"
                />
              </label>
            </div>
            <div className="mb-3">
              {files.length > 0 && (
                <ul className="mt-2 space-y-1">
                  {files.map((file, index) => (
                    <li
                      key={index}
                      className="text-gray-700 bg-white-secondary w-fit h-fit shadow-sm shadow-white-tertiary px-2 py-1 flex flex-row justify-start items-center gap-5"
                    >
                      {file.name}
                      <div
                        className="scale-125 cursor-pointer text-[#d73d32] hover:text-[#b73d32]"
                        onClick={() => removeFileUpload(index)}
                      >
                        <IoIosCloseCircle />
                      </div>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
          <div className="xl:w-1/3 w-full h-fit flex flex-col justify-center items-start gap-1">
            <span>{t("company.company_avatar")}</span>
            <FileUpload
              currentImageUrl={companyInformation?.image?.original || ""}
              onImageUpload={setUploadedImage}
            />
          </div>
        </div>
        <div className="w-full px-3 pb-5 flex flex-col gap-1">
          <span>{t("company.information")}</span>
          <CKEditor5
            getDataOnChange={setCkeditorData}
            initialData={ckeditorData}
          />
        </div>
        <div className="w-full h-fit flex justify-end items-center px-3">
          <span
            className="w-fit h-fit py-3 px-5 bg-primary text-white font-semibold rounded-md shadow-md hover:bg-primary-hover cursor-pointer mt-1 mb-7"
            onClick={() => {
              handleChangeCompanyInformation();
            }}
          >
            {t("company.save_changes")}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Information;

import { FaGoogle } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { toast } from "react-toastify";
import { isEmpty, isEnoughLength, isValidateEmail } from "../../utils/utils";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { handleRequestEmail, loginUser, registerUser } from "../../api/userApi";
import { jwtDecode } from "jwt-decode";
import ConfirmEmail from "../../components/confirmEmail";
import { useSelector } from "react-redux";

const AuthPage = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const theme = useSelector((state) => state.themeReducer);
  const [isLogin, setIsLogin] = useState(true);
  const [isShowConfirm, setIsShowConfirm] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [inputType, setInputType] = useState("password");
  const [isShowPassword, setIsShowPassword] = useState(false);

  const handleRegister = async () => {
    if (isEmpty(name) || isEmpty(email) || isEmpty(password)) {
      toast.error(t("notify.empty_fields"), {});
      return;
    }

    if (!isValidateEmail(email)) {
      toast.error(t("notify.invalid_email"), {});
      return;
    }

    if (!isEnoughLength(password, 6)) {
      toast.error(t("notify.password_too_short"), {});
      return;
    }

    if (password !== confirmPassword) {
      toast.error(t("notify.password_not_match"), {});
      return;
    }

    const data = {
      name: name,
      email: email,
      username: email,
      role: 3,
      password: password,
    };

    await registerUser(data).then(
      () => {
        toast.success(t("notify.register_success"), {});
        toast.success(t("notify.send_confirm_email_notify"), {});

        handleSendConfirmEmail({
          email: email,
        });
      },
      (error) => {
        toast.error(error.response.data.error.message, {});
      }
    );
  };

  const handleSendConfirmEmail = (data) => {
    handleRequestEmail(data);
    setIsShowConfirm(true);
  };

  const handleLogin = async () => {
    try {
      // Validate form
      if (isEmpty(email) || isEmpty(password)) {
        toast.error(t("notify.empty_fields"), {});
        return;
      }
      if (!isValidateEmail(email)) {
        toast.error(t("notify.invalid_email"), {});
        return;
      }
      if (!isEnoughLength(password, 6)) {
        toast.error(t("notify.password_too_short"), {});
        return;
      }

      const data = {
        identifier: email,
        password: password,
      };

      // Send login request
      console.log('DFJlskfjaslkdfjkldsfjasldkfajsdkfljasdlk');
      const response = await loginUser(data);
      console.log(response);

      toast.success(t("notify.login_success"));
      Cookies.set(process.env.REACT_APP_JWT_NAME, response.jwt, {
        sameSite: "lax",
      });

      const userRole = jwtDecode(response.jwt).role;

      if (userRole === "Admin") {
        navigate("/university");
      } else if (userRole === "Company") {
        navigate("/company");
      } else {
        navigate("/");
      }
    } catch (error) {
      toast.error(error.response.data.error.message, {});
      if (
        error.response.data.error.status === 400 &&
        error.response.data.error.message ===
        "Your account email is not confirmed"
      ) {
        toast.info(t("notify.send_confirm_email_notify"), {});
        handleSendConfirmEmail({
          email: email,
        });
      }
    }
  };

  return (
    <div className="w-full h-full relative">
      <img
        className="w-full h-[100vh] object-cover"
        src={theme.backgroundUrl || ""}
        alt=""
      />
      <div className="w-full h-full bg-black opacity-50 absolute top-0 z-10" />
      {isShowConfirm && (
        <div className="absolute top-[10%] left-1/2 -translate-x-1/2 w-fit h-fit z-20">
          <ConfirmEmail email={email} />
        </div>
      )}
      <div
        className={`${isLogin
          ? "lg:w-[750px] sm:w-[400px] w-[97%] min-w-[350px]"
          : "sm:w-[450px] w-[97%] min-w-[350px]"
          }  h-fit absolute top-20 left-1/2 -translate-x-1/2 bg-login-background text-login-text z-20 flex flex-col justify-center items-center pt-7 pb-2 px-5 rounded-md ${isShowConfirm ? "scale-0" : "scale-100"
          } `}
      >
        <div className="cursor-pointer">
          <img
            src={theme.logoUrl || "no_image.png"}
            alt=""
            className="w-[70px] h-[70px]"
          />
        </div>
        <span className="text-[40px] font-bold text-login-text">
          {theme.websiteName}
        </span>
        <div
          className={`w-full h-fit flex lg:flex-row flex-col-reverse justify-center lg:items-start items-center gap-3 pb-2 duration-300 ${isLogin ? "scale-100" : "scale-0 w-0 max-h-0"
            }`}
        >
          <div className="lg:w-1/2 w-full flex flex-col justify-center items-center px-5">
            <div className="w-full h-fit">
              <span className="text-[15px]">{t("auth.email")}</span>
              <div className="w-full h-fit border-login-background-hover rounded-sm border-[1px] px-5 py-2 flex flex-row justify-between items-center">
                <input
                  className="outline-none bg-transparent placeholder-login-text/9 border-none flex-grow text-sm"
                  placeholder="example@gmail.com"
                  type="text"
                  onChange={(e) => setEmail(e.target.value)}
                  tabIndex={1}
                />
              </div>
            </div>
            <div className="w-full h-fit pt-2">
              <span className="text-[15px]">{t("auth.password")}</span>
              <div className="w-full h-fit border-login-background-hover rounded-sm border-[1px] px-5 py-2 flex flex-row justify-between items-center">
                <input
                  className="outline-none bg-transparent placeholder-login-text/9 border-none flex-grow text-sm"
                  placeholder={t("auth.password")}
                  type={inputType}
                  onChange={(e) => setPassword(e.target.value)}
                  tabIndex={2}
                />
                <div
                  className="scale-110 cursor-pointer"
                  onClick={() => {
                    setIsShowPassword(!isShowPassword);
                    setInputType(isShowPassword ? "password" : "text");
                  }}
                >
                  {!isShowPassword ? <FaEyeSlash /> : <FaEye />}
                </div>
              </div>
            </div>
            <span
              className="w-1/2 h-fit flex justify-center items-center px-5 py-3 bg-primary hover:bg-primary-hover text-center text-header-text font-bold rounded-sm mt-5 cursor-pointer"
              onClick={() => {
                handleLogin();
              }}
            >
              {t("auth.login")}
            </span>
            <div
              className="text-sm mt-3 cursor-pointer hover:underline hover:text-primary-hover"
              onClick={() => setIsLogin(false)}
            >
              <span>{t("auth.dont_have_an_account_register_now")}</span>
            </div>
          </div>
          <div className="w-[2px] h-[200px] rounded-full bg-login-background-hover lg:block hidden" />
          <div className="w-[270px] h-[2px] rounded-full bg-login-background-hover lg:hidden block my-4 " />

          <div className="lg:w-1/2 w-full flex flex-col justify-center items-center px-5 gap-3 text-[#282828]">
            <span>{t("auth.student_login")}</span>
            <div
              className="w-full h-fit px-5 py-3 bg-[#d73d32] hover:bg-[#b73d32] text-center text-white-primary font-semibold rounded-sm flex flex-row items-center justify-center gap-3 cursor-pointer"
              onClick={() =>
                (window.location = `${window.location.origin}/api/connect/google`)
              }
            >
              <FaGoogle className="scale-125" />
              <span className="ml-2">{t("auth.login_with_google")}</span>
            </div>
          </div>
        </div>

        <div
          className={`w-full h-fit flex flex-col justify-center items-center gap-1 pb-2 duration-300 ${!isLogin ? "scale-100" : "scale-0 w-0 max-h-0"
            }`}
        >
          <span className="text-primary text-[15px] font-semibold">
            <em>{t("auth.note")}</em>
          </span>
          <div className="w-full flex flex-col justify-center items-center gap-2 px-5">
            <div className="w-full h-fit">
              <span className="text-[15px]">{t("auth.fullname")}</span>
              <div className="w-full h-fit border-login-background-hover rounded-sm border-[1px] px-5 py-2 flex flex-row justify-between items-center">
                <input
                  className="outline-none bg-transparent border-none flex-grow text-sm"
                  placeholder={t("auth.fullname")}
                  type="text"
                  onChange={(e) => setName(e.target.value)}
                  tabIndex={1}
                />
              </div>
            </div>
            <div className="w-full h-fit">
              <span className="text-[15px]">{t("auth.email")}</span>
              <div className="w-full h-fit border-login-background-hover rounded-sm border-[1px] px-5 py-2 flex flex-row justify-between items-center">
                <input
                  className="outline-none bg-transparent border-none flex-grow text-sm"
                  placeholder="example@gmail.com"
                  type="text"
                  onChange={(e) => setEmail(e.target.value)}
                  tabIndex={2}
                />
              </div>
            </div>
            <div className="w-full h-fit">
              <span className="text-[15px]">{t("auth.password")}</span>
              <div className="w-full h-fit border-login-background-hover rounded-sm border-[1px] px-5 py-2 flex flex-row justify-between items-center">
                <input
                  className="outline-none bg-transparent border-none flex-grow text-sm"
                  placeholder={t("auth.password")}
                  type={inputType}
                  onChange={(e) => setPassword(e.target.value)}
                  tabIndex={3}
                />
                <div
                  className="scale-110 cursor-pointer"
                  onClick={() => {
                    setIsShowPassword(!isShowPassword);
                    setInputType(isShowPassword ? "password" : "text");
                  }}
                >
                  {!isShowPassword ? <FaEyeSlash /> : <FaEye />}
                </div>
              </div>
            </div>
            <div className="w-full h-fit">
              <span className="text-[15px]">{t("auth.confirm_password")}</span>
              <div className="w-full h-fit border-login-background-hover rounded-sm border-[1px] px-5 py-2 flex flex-row justify-between items-center">
                <input
                  className="outline-none bg-transparent border-none flex-grow text-sm"
                  placeholder={t("auth.confirm_password")}
                  type={inputType}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  tabIndex={4}
                />
                <div
                  className="scale-110 cursor-pointer"
                  onClick={() => {
                    setIsShowPassword(!isShowPassword);
                    setInputType(isShowPassword ? "password" : "text");
                  }}
                >
                  {!isShowPassword ? <FaEyeSlash /> : <FaEye />}
                </div>
              </div>
            </div>
            <span
              className="w-1/2 h-fit flex justify-center items-center px-5 py-3 bg-primary hover:bg-primary-hover text-center text-header-text font-bold rounded-sm mt-3 cursor-pointer"
              onClick={() => handleRegister()}
            >
              {t("auth.register")}
            </span>
            <span
              className=" text-sm m-1 cursor-pointer hover:underline hover:text-primary-hover"
              onClick={() => setIsLogin(true)}
            >
              {t("auth.already_have_an_account_login_now")}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthPage;

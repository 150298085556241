import React, { useEffect, useState } from "react";
import { IoCopyOutline } from "react-icons/io5";
import { FaCheckCircle } from "react-icons/fa";
import useCopyToClipboard from "../../custom/hook/useCopyToClipboard";
import { useSelector } from "react-redux";
import { MdOutlineOpenInNew } from "react-icons/md";
import Loading from "../loading";
import { useTranslation } from "react-i18next";

const Content = () => {
  const company = useSelector((state) => state.companyInformationReducer);
  const { t } = useTranslation();

  const [addressCopied, setAddressCopied] = useState(false);
  const [phoneCopied, setPhoneCopied] = useState(false);
  const [emailCopied, setEmailCopied] = useState(false);

  const [copiedText, copy] = useCopyToClipboard();

  const handleCopy = (content) => {
    copy(content).then((success) => {
      if (success) {
        console.log("Copy successful!");
      } else {
        console.log("Copy failed!");
      }
    });
  };

  if (!company) {
    return (
      <div className="w-full h-[300px] flex justify-center items-center">
        <Loading />
      </div>
    );
  }

  return (
    <div className="w-full h-full lg:px-20 px-3 py-3  flex flex-col gap-3 overflow-auto">
      <div className="w-full h-fit flex flex-col justify-center items-center gap-2">
        <img
          src={
            company.image
              ? company.image.url
              : 'no_image.png'
          }
          alt=""
          className="w-[100px] h-[100px] object-contain my-3"
        />
        <span className="text-2xl font-bold">{company.fullname}</span>
      </div>
      <div className="w-full h-fit flex flex-col justify-start items-start gap-1">
        <div className="w-full h-[1px] bg-white-secondary" />

        <span className="text-md font-semibold mt-3 text-primary">
          {t("overview.information_from_company")}
        </span>
        <div className="w-full h-fit flex flex-col justify-center items-center lg:px-7 px-3">
          <div
            className="w-full h-fit flex flex-col"
            dangerouslySetInnerHTML={{ __html: company.work }}
          />
        </div>
      </div>
      <div className="w-full h-fit flex flex-col justify-start items-start gap-1">
        <div className="w-full h-[1px] bg-white-secondary" />

        <span className="text-md font-semibold mt-3 text-primary">
          {t("overview.information_about_internship_program")}{" "}
          {company.fullname}
        </span>
        <div className="w-full h-fit flex flex-col lg:flex-row lg:px-7 px-3 pt-1 gap-2 flex-wrap">
          {company.internshipFiles.map((file, index) => {
            return (
              <div
                key={index}
                className="text-white-primary bg-primary w-fit h-fit shadow-sm shadow-white-tertiary px-2 py-1 flex flex-row justify-start items-center gap-5 rounded-[4px] hover:bg-primary-hover"
              >
                <a
                  href={file.file.url}
                  key={index}
                  target="_blank"
                  className="w-full h-fit flex flex-row gap-3 justify-start items-center text-white-primary"
                >
                  <span>{file.name}</span>
                  <div className="scale-125 cursor-pointer text-white-primary">
                    <MdOutlineOpenInNew />
                  </div>
                </a>
              </div>
            );
          })}
        </div>
      </div>
      <div className="w-full h-fit flex flex-col justify-start items-start gap-1">
        <div className="w-full h-[1px] bg-white-secondary" />

        <span className="text-md font-semibold mt-3 text-primary">
          {t("overview.quantity_information")}
        </span>

        <div className="w-full h-fit flex lg:flex-row flex-col lg:px-7 px-3">
          <div className="w-full h-fit flex flex-col lg:px-7 px-0">
            <div>
              <span className="scale-150">• </span>
              <span>
                {t("overview.maximum_number_of_registrations") + ": "}
              </span>
              <span className="font-bold ml-2">{company.maxRegister}</span>
            </div>
            <div>
              <span className="scale-150">• </span>
              <span>{t("overview.maximum_number_of_accepted") + ": "}</span>
              <span className="font-bold ml-2">
                {company.maxAcceptedStudent}
              </span>
            </div>
          </div>
          <div className="w-full h-fit flex flex-col lg:px-7 px-0">
            <div>
              <span className="scale-150">• </span>
              <span>
                {t("overview.the_number_of_students_registered") + ": "}
              </span>
              <span className="font-bold ml-2">{company.currentRegister}</span>
            </div>
            <div>
              <span className="scale-150">• </span>
              <span>
                {t("overview.the_number_of_students_accepted") + ": "}
              </span>
              <span className="font-bold ml-2">
                {company.currentAcceptedStudent}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full h-fit flex flex-col justify-start items-start">
        <div className="w-full h-[1px] bg-white-secondary" />

        <span className="text-md font-semibold mt-3 text-primary">
          {t("overview.contact_information")}
        </span>
        <div className="w-full h-fit flex flex-col px-2 lg:px-7 gap-1">
          <div className="w-full h-fit flex flex-row gap-3 justify-start lg:items-center items-start">
            <span>{t("overview.address") + ": "}</span>
            <div>
              <span>
                {!company.address || company.address === ""
                  ? "[Trống]"
                  : company.address}
              </span>
            </div>
            <div
              className={`flex flex-row justify-center items-center gap-1 bg-[#505050] hover:bg-black-secondary text-white-primary px-2 py-1 rounded-md cursor-pointer shadow-md scale-90 ${
                addressCopied ? "bg-black-secondary" : ""
              }`}
              onClick={() => {
                handleCopy(company.address);
                setAddressCopied(true);
              }}
            >
              <span>{addressCopied ? "Copied" : "Copy"}</span>
              {addressCopied ? <FaCheckCircle /> : <IoCopyOutline />}
            </div>
          </div>
          <div className="w-full h-fit flex flex-row gap-3 justify-start lg:items-center items-start">
            <span>{t("overview.phone_number") + ": "}</span>
            <div>
              <span>
                {!company.phoneNumber || company.phoneNumber === ""
                  ? "[Trống]"
                  : company.phoneNumber}
              </span>
            </div>
            <div
              className={`flex flex-row justify-center items-center gap-1 bg-[#505050] hover:bg-black-secondary text-white-primary px-2 py-1 rounded-md cursor-pointer shadow-md scale-90 ${
                phoneCopied ? "bg-black-secondary" : ""
              }`}
              onClick={() => {
                handleCopy(company.phone);
                setPhoneCopied(true);
              }}
            >
              <span>{phoneCopied ? "Copied" : "Copy"}</span>
              {phoneCopied ? <FaCheckCircle /> : <IoCopyOutline />}
            </div>
          </div>
          <div className="w-full h-fit flex flex-row gap-3 justify-start lg:items-center items-start">
            <span>{t("overview.email") + ": "}</span>
            <div>
              <span>
                {!company.contactEmail || company.contactEmail === ""
                  ? "[Trống]"
                  : company.contactEmail}
              </span>
            </div>
            <div
              className={`flex flex-row justify-center items-center gap-1 bg-[#505050] hover:bg-black-secondary text-white-primary px-2 py-1 rounded-md cursor-pointer shadow-md scale-90 ${
                emailCopied ? "bg-black-secondary" : ""
              }`}
              onClick={() => {
                handleCopy(company.email);
                setEmailCopied(true);
              }}
            >
              <span>{emailCopied ? "Copied" : "Copy"}</span>
              {emailCopied ? <FaCheckCircle /> : <IoCopyOutline />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Content;

import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    showOverlay: false,
}

const pageOverlaySlice = createSlice({
    name: 'pageOverlay',
    initialState,
    reducers: {
        showOverlay: (state) => {
            state.showOverlay = true;
        },
        hideOverlay: (state) => {
            state.showOverlay = false;
        }
    }
})

export const { showOverlay, hideOverlay } = pageOverlaySlice.actions;

export default pageOverlaySlice.reducer;

import { toast } from "react-toastify";
import { axiosWithJWT } from "../config/axiosConfig";

const uploadFile = async (ref, refId, field, file) => {
  const formData = new FormData();

  if (ref) {
    formData.append("ref", ref);
  }
  if (refId) {
    formData.append("refId", refId);
  }
  if (field) {
    formData.append("field", field);
  }
  if (file) {
    formData.append("files", file);
  }

  try {
    const response = await axiosWithJWT.post(`/api/upload`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data;
  } catch (error) {
    toast.error(error.response.data.error.message);
    return null;
  }
};

export { uploadFile };

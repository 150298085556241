import { toast } from "react-toastify";
import { axiosWithJWT } from "../config/axiosConfig";

const updateUniversity = async (universityId, updatedData) => {
    try {
        const response = await axiosWithJWT.put(
            `/api/companies/${universityId}`,
            {
                data: updatedData,
            }
        );

        return response.data;
    } catch (error) {
        toast.error(error.response.data.error.message);
        return null;
    }
};

export { updateUniversity };

import React, { useEffect, useState } from "react";
import CKEditor5 from "../ckeditor";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "../../utils/utils";
import { getUserInformation } from "../../api/userApi";
import { changeUserInformationRedux } from "../../utils/asyncReduxUtils";
import { toast } from "react-toastify";
import { uploadFile } from "../../api/uploadFile";
import { updateUniversity } from "../../api/universityApi";
import { axiosWithJWT } from "../../config/axiosConfig";
import { Modal, Button } from "@mui/material";
import { confirmAlert } from "../react-confirm-alert/lib";
import CsvDropzone from "../upload/csv";
import ImageDropzone from "../upload/image";

const Information = () => {
  const { t } = useTranslation();

  const universityInformation = useSelector(
    (state) => state.userInformationReducer.company
  );

  const dispatch = useDispatch();

  // State for information
  const [universityFullName, setUniversityFullName] = useState(
    universityInformation.fullname || ""
  );
  const [universityShortname, setUniversityShortname] = useState(
    universityInformation.shortname || ""
  );
  const [universityAddress, setUniversityAddress] = useState(
    universityInformation.address || ""
  );
  const [universityPhoneNumber, setUniversityPhoneNumber] = useState(
    universityInformation.phoneNumber || ""
  );
  const [universityContactEmail, setUniversityContactEmail] = useState(
    universityInformation.contactEmail || ""
  );
  const [ckeditorData, setCkeditorData] = useState(
    universityInformation.work || ""
  );

  // State for uploaded image
  const [uploadedImage, setUploadedImage] = useState(null);
  const [uploadedStudentData, setUploadedStudentData] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const updateCompanyInformation = async () => {
    try {
      // Update basic information first
      await updateUniversity(universityInformation.id, {
        fullname: universityFullName,
        shortname: universityShortname,
        address: universityAddress,
        phoneNumber: universityPhoneNumber,
        contactEmail: universityContactEmail,
        work: ckeditorData,
      });

      if (uploadedImage) {
        await uploadFile(
          "api::company.company",
          universityInformation.id,
          "image",
          uploadedImage
        );
        setUploadedImage(null);
      }

      toast.success(t("notify.update_success"));

      // Get user information again
      const userData = await getUserInformation();

      // Update user information in Redux
      changeUserInformationRedux(dispatch, userData);
    } catch (error) {
      // Handle error from any of the above actions
      toast.error(error.response?.data?.error?.message || "An error occurred");
    }
  };

  const handleChangeUniversityInformation = async () => {
    // Check if fields are empty
    if (isEmpty(universityFullName) || isEmpty(universityAddress)) {
      toast.error(t("notify.empty_fields"), {});
      return;
    }

    confirmAlert({
      title: "",
      message: t("notify.are_you_sure_you_want_to_update"),
      buttons: [
        {
          label: "Yes",
          onClick: () => updateCompanyInformation(),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const handleFileChange = (csvFile) => {
    if (csvFile) {
      setUploadedStudentData(csvFile);
    }
  };

  const handleImportData = () => {
    const formData = new FormData();
    formData.append("file", uploadedStudentData);
    axiosWithJWT
      .post(`/api/users/uploadData`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(() => {
        toast.success(t("notify.upload_success"));
      })
      .catch(() => {
        toast.error(t("notify.upload_failed"));
      });

    setOpenModal(false);
  };

  return (
    <div className="w-full flex-grow flex flex-col justify-start items-start bg-black-tertiary p-3 overflow-auto text-sm">
      <div className="w-full h-fit bg-white-primary p-3 rounded-md shadow-md shadow-white-tertiary">
        <span className="text-lg font-semibold">
          {t("university.university_information")}
        </span>
        <div className="w-full h-fit flex xl:flex-row flex-col items-center px-3 pt-5 gap-7">
          <div className="xl:w-2/3 w-full h-full">
            <span>{t("university.university_fullname")}</span>
            <input
              type="text"
              value={universityFullName}
              placeholder={t("university.university_fullname")}
              className="mt-1 mb-3 w-full border border-white-secondary focus:border-white-tertiary outline-none py-[7px] px-4 rounded-md shadow-sm"
              onChange={(e) => setUniversityFullName(e.target.value)}
            />
            <span>{t("university.university_shortname")}</span>
            <input
              type="text"
              value={universityShortname}
              placeholder={t("university.university_shortname")}
              className="mt-1 mb-3 w-full border border-white-secondary focus:border-white-tertiary outline-none py-[7px] px-4 rounded-md shadow-sm"
              onChange={(e) => setUniversityShortname(e.target.value)}
            />
            <span>{t("university.university_address")}</span>
            <input
              type="text"
              value={universityAddress}
              placeholder={t("university.university_address")}
              className="mt-1 mb-3 w-full border border-white-secondary focus:border-white-tertiary outline-none py-[7px] px-4 rounded-md shadow-sm"
              onChange={(e) => setUniversityAddress(e.target.value)}
            />
            <span>{t("company.phone_number")}</span>
            <input
              type="text"
              tabIndex={4}
              placeholder={t("company.phone_number")}
              className="mt-1 mb-3 w-full border border-white-secondary focus:border-white-tertiary outline-none py-[7px] px-4 rounded-md shadow-sm"
              value={universityPhoneNumber}
              onChange={(e) => setUniversityPhoneNumber(e.target.value)}
            />

            <span>{t("company.email_contact")}</span>
            <input
              type="text"
              tabIndex={5}
              placeholder={t("company.email_contact")}
              className="mt-1 mb-3 w-full border border-white-secondary focus:border-white-tertiary outline-none py-[7px] px-4 rounded-md shadow-sm"
              value={universityContactEmail}
              onChange={(e) => setUniversityContactEmail(e.target.value)}
            />
            <button
              onClick={() => setOpenModal(true)} // Open modal
              className="w-full h-fit py-3 px-5 mb-3 bg-primary text-white font-semibold rounded-md shadow-md hover:bg-primary-hover cursor-pointer"
            >
              {t("university.import_student_data")}
            </button>
          </div>
          <div className="flex flex-col w-96">
            <div className="w-full h-fit flex flex-col justify-center items-start gap-1 mb-4 p-4 ">
              {" "}
              {/* Added margin, padding, border, and shadow */}
              <span>{t("university.university_avatar")}</span>
              <ImageDropzone
                currentImageUrl={universityInformation?.image?.original || ""}
                onImageUpload={setUploadedImage}
              />
            </div>
          </div>
        </div>
        <div className="w-full px-3 pb-5 flex flex-col gap-1">
          <span>{t("university.information")}</span>
          <CKEditor5
            getDataOnChange={setCkeditorData}
            initialData={ckeditorData}
          />
        </div>
        <div className="w-full h-fit flex justify-end items-center px-3">
          <span
            className="w-fit h-fit py-3 px-5 bg-primary text-white font-semibold rounded-md shadow-md hover:bg-primary-hover cursor-pointer mt-1 mb-7"
            onClick={handleChangeUniversityInformation}
          >
            {t("university.save_changes")}
          </span>
        </div>
      </div>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="import-data-modal"
        aria-describedby="modal-to-import-student-data"
      >
        <div className="flex flex-col items-center p-6 bg-white rounded-md shadow-lg w-1/2 mx-auto mt-20">
          <h2 id="import-data-modal" className="text-xl font-semibold mb-4">
            {t("university.import_student_data")}
          </h2>
          <CsvDropzone onCsvUpload={handleFileChange} />
          <div className="w-full h-fit flex flex-row justify-between items-center gap-5">
            <div className="flex-grow text-[13px] italic pt-3 text-[#d62638]">
              <p>{"* " + t("notify.csv_file_note")}</p>
              <p>{"* " + t("notify.csv_field_note")}</p>
            </div>
            <div className="flex justify-end w-fit mt-4 gap-2">
              <Button
                variant="outlined"
                onClick={() => setOpenModal(false)}
                className="mr-2"
                sx={{
                  textTransform: "none",
                  borderColor: "var(--primary-color)",
                  color: "var(--primary-color)",
                }}
              >
                {t("university.cancel")}
              </Button>
              <Button
                variant="contained"
                onClick={handleImportData}
                sx={{
                  textTransform: "none",
                  backgroundColor: "var(--primary-color)",
                }}
              >
                {t("university.save")}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Information;

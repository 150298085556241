import { setUserInformation } from "../store/common/userInformationSlice";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { getUserInformation } from "../api/userApi";
import { getCompanyById } from "../api/companyApi";
import { setCompanyInformation } from "../store/common/companyInformationSlice";
import { setThemeData } from "../store/common/themeSlice";

const changeUserInformationRedux = async (dispatch, user) => {
  try {
    const arrayFile =
      user.company?.internshipFiles?.map((file) => {
        return {
          id: file.id,
          name: file.name,
          fileId: file.file.id,
          url: file.file.url,
        };
      }) || [];

    dispatch(
      setUserInformation({
        id: user.id,
        name: user.name,
        email: user.email,
        gender: user.gender,
        image: {
          id: user.image?.id,
          original: user.image?.url || "",
          thumbnail: user.image?.formats?.thumbnail?.url || "",
          small: user.image?.formats?.small?.url || "",
          medium: user.image?.formats?.medium?.url || "",
          large: user.image?.formats?.large?.url || "",
        },
        phoneNumber: user.phoneNumber,
        organizationId: user.organizationId,
        isAccepted: user.isAccepted,
        role: jwtDecode(Cookies.get(process.env.REACT_APP_JWT_NAME)).role,
        registerInternships: user.registerInternships,
        company: user.company
          ? {
            id: user.company.id,
            shortname: user.company.shortname,
            fullname: user.company.fullname,
            address: user.company.address,
            contactEmail: user.company.contactEmail,
            phoneNumber: user.company.phoneNumber,
            work: user.company.work,
            maxAcceptedStudent: user.company.maxAcceptedStudent,
            maxRegister: user.company.maxRegister,
            adminMaxRegister: user.company.adminMaxRegister,
            acceptedIntern: user.company.acceptedIntern,
            isAccepted: user.company.isAccepted,
            blocked: user.company.blocked,
            image: {
              id: user.company.image?.id,
              original: user.company.image?.url || "",
              thumbnail: user.company.image?.formats?.thumbnail?.url || "",
              small: user.company.image?.formats?.small?.url || "",
              medium: user.company.image?.formats?.medium?.url || "",
              large: user.company.image?.formats?.large?.url || "",
            },
            internshipFiles: arrayFile,
            registerInternships: user.company.registerInternships,
          }
          : null,
      })
    );
  } catch (error) {
    throw error;
  }
};

const asyncUserInformation = async (dispatch) => {
  const token = Cookies.get(process.env.REACT_APP_JWT_NAME);
  if (!token) {
    return;
  }
  getUserInformation()
    .then((user) => {
      if (!user) {
        return;
      }
      changeUserInformationRedux(dispatch, user)
        .then(() => { })
        .catch((error) => {
          console.log(error);
        });
    })
    .catch((error) => {
      console.log(error);
    });
};

const asyncCompanyInformation = async (dispatch, id) => {
  getCompanyById(id)
    .then((company) => {
      dispatch(setCompanyInformation(company));
    })
    .catch((error) => {
      console.log(error);
    });
};

const asyncThemeInformation = async (dispatch, themeData) => {
  try {
    if (themeData) {
      const css = {
        primaryColor: themeData.css.primaryColor,
        primaryColorRGB: themeData.css.primaryColorRGB,
        loginTextColor: themeData.css.loginTextColor,
        loginTextColorRGB: themeData.css.loginTextColorRGB,
        headerTextColor: themeData.css.headerTextColor,
        headerTextColorRGB: themeData.css.headerTextColorRGB,
        navigateTextColor: themeData.css.navigateTextColor,
        navigateTextColorRGB: themeData.css.navigateTextColorRGB,
        loginBackgroundColor: themeData.css.loginBackgroundColor,
        loginBackgroundColorRGB: themeData.css.loginBackgroundColorRGB,
        navigateBackgroundColor: themeData.css.navigateBackgroundColor,
        navigateBackgroundColorRGB: themeData.css.navigateBackgroundColorRGB,
      };

      const websiteName = themeData.websiteName || "";

      const logoUrl = themeData.logo?.url || "";
      const backgroundUrl = themeData.background?.url || "";

      dispatch(
        setThemeData({
          id: themeData.id,
          css,
          websiteName,
          logoUrl,
          backgroundUrl,
        })
      );
    }
  } catch (error) {
    console.error("Error loading theme:", error);
  }
};


export {
  asyncUserInformation,
  asyncCompanyInformation,
  changeUserInformationRedux,
  asyncThemeInformation
};


import { axiosWithJWT } from "../config/axiosConfig";

const getStatistic = async () => {
  try {
    const response = await axiosWithJWT.get(
      `/api/register-internship/statistic`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export { getStatistic };

import React, { useEffect, useRef, useState } from "react";
import { AiOutlineCaretDown } from "react-icons/ai";
import DropdownMenu from "../menu/dropdownMenu";
import { GrLanguage } from "react-icons/gr";
import { RiLogoutCircleLine } from "react-icons/ri";
import MenuItem from "../menu/menuItem";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FaCheck } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { hideOverlay, showOverlay } from "../../store/common/pageOverlaySlice";
import { PiStudent } from "react-icons/pi";
import { IoCloseCircle } from "react-icons/io5";
import Cookies from "js-cookie";
import { logoutUser } from "../../store/common/userInformationSlice";
import { toast } from "react-toastify";

const UniversityHeader = ({ listMenuItems = [] }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [buttonAction, setButtonAction] = useState(false);

  const [showMenuLanguage, setShowMenuLanguag] = useState(false);

  const [showMainMenu, setShowMainMenu] = useState(true);

  const navigate = useNavigate();

  const { t, i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);

  const isShowOverlay = useSelector(
    (state) => state.pageOverlayReducer.showOverlay
  );
  const dispartch = useDispatch();

  const isFirstRender = useRef(true);

  const currentUser = useSelector((state) => state.userInformationReducer);
  const theme = useSelector((state) => state.themeReducer);

  const handleMainMenuClick = () => {
    setShowMainMenu(!showMainMenu);
  };

  const handleChangeLanguage = (language) => {
    setCurrentLanguage(language);
    i18n.changeLanguage(language);
  };

  const handleLogout = () => {
    Cookies.remove(process.env.REACT_APP_JWT_NAME, { sameSite: "lax" });
    dispartch(logoutUser());
    toast.success(t("notify.logout_success"));
    navigate("/");
  };

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    if (!isShowOverlay) {
      if (window.innerWidth <= 1024) {
        setShowMainMenu(false);
      }
      setShowMenu(false);
      setShowMenuLanguag(false);
    }
  }, [isShowOverlay]);

  useEffect(() => {
    window.addEventListener("resize", () => {
      if (window.innerWidth <= 1024) {
        if (showMainMenu) {
          setShowMainMenu(false);
        }
      } else {
        if (!showMainMenu) {
          setShowMainMenu(true);
        }
      }
    });
  }, [window.innerWidth]);

  return (
    <div
      className={`w-full h-[70px] bg-primary text-header-text flex flex-row justify-between items-center relative`}
    >
      <div className="w-fit h-full flex flex-row justify-start items-center flex-grow gap-3 px-5">
        <img
          className="min-w-[55px] min-h-[55px] w-[55px] h-[55px] bg-white rounded-full cursor-pointer"
          src={theme.logoUrl ? theme.logoUrl : 'no_image.png'}
          alt=""
          onClick={() => navigate("/")}
        />
        <span className="text-3xl font-bold ml-1">{theme.websiteName}</span>
      </div>

      <div
        className={`w-[350px] lg:w-fit h-fit flex lg:px-0 lg:py-0 px-2 py-5 lg:flex-row flex-col-reverse lg:bg-transparent bg-primary lg:static absolute top-[75px] right-1 justify-center items-center gap-5 rounded-md lg:rounded-none z-10 origin-top-right duration-200 ${
          showMainMenu ? "scale-100" : "scale-0"
        }`}
      >
        <div className="w-full h-fit block lg:hidden">
          {listMenuItems.map((item, index) => {
            return listMenuItems[index];
          })}
        </div>
        <div
          className="w-full lg:h-[70px] h-12 px-4 gap-2 cursor-pointer flex flex-row justify-start items-center hover:bg-primary-hover relative"
          onClick={() => {
            setShowMenu(!showMenu);
            setButtonAction("account_options");
            if (window.innerWidth > 1024) {
              dispartch(isShowOverlay ? hideOverlay() : showOverlay());
            }
          }}
        >
          <img
            src={
              currentUser.image.thumbnail
                ? currentUser.image.thumbnail
                : currentUser.image.original
                ? currentUser.image.original
                : 'no_user.png'
            }
            className="w-[40px] h-[40px] bg-white-primary rounded-full p-[1px] object-contain"
            alt=""
          />
          <span className="flex-grow truncate">{currentUser.name}</span>
          {showMenu && buttonAction === "account_options" ? (
            <div onClick={() => dispartch(hideOverlay())}>
              <IoCloseCircle />
            </div>
          ) : (
            <AiOutlineCaretDown />
          )}

          <div
            className={`absolute top-full right-0 md:right-[103%] duration-300 origin-top lg:right-1 ${
              showMenu && buttonAction === "account_options"
                ? "scale-100"
                : "scale-0"
            }`}
          >
            <DropdownMenu
              listMenuItems={[
                !currentUser.isAthenticated && (
                  <MenuItem
                    menuIcon={<PiStudent />}
                    menuContext={t("header.intership")}
                    onClick={() => {
                      navigate("/");
                      dispartch(hideOverlay());
                    }}
                  />
                ),
                <div
                  className={`relative`}
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowMenuLanguag(!showMenuLanguage);
                  }}
                >
                  <MenuItem
                    menuIcon={<GrLanguage />}
                    menuContext={t("header.language")}
                    otherComponent={
                      showMenuLanguage ? (
                        <div onClick={() => dispartch(hideOverlay())}>
                          <IoCloseCircle />
                        </div>
                      ) : (
                        <AiOutlineCaretDown />
                      )
                    }
                  />
                  <div
                    className={`absolute top-full duration-300 right-0 origin-top ${
                      showMenuLanguage ? "scale-100" : "scale-0"
                    }`}
                  >
                    <DropdownMenu
                      listMenuItems={[
                        <MenuItem
                          menuContext="English"
                          onClick={() => {
                            handleChangeLanguage("en");
                            dispartch(hideOverlay());
                          }}
                          otherComponent={
                            currentLanguage === "en" ? <FaCheck /> : ""
                          }
                        />,
                        <MenuItem
                          menuContext="Vietnamese"
                          onClick={() => {
                            handleChangeLanguage("vi");
                            dispartch(hideOverlay());
                          }}
                          otherComponent={
                            currentLanguage === "vi" ? <FaCheck /> : ""
                          }
                        />,
                      ]}
                      width="w-[190px]"
                    />
                  </div>
                </div>,
                !currentUser.isAthenticated && (
                  <MenuItem
                    menuIcon={<RiLogoutCircleLine />}
                    menuContext={t("header.logout")}
                    onClick={handleLogout}
                  />
                ),
              ]}
              width="w-[300px]"
            />
          </div>
        </div>
      </div>

      <div
        className="w-fit h-fit p-2 hover:bg-primary-hover cursor-pointer rounded-sm mx-3 lg:hidden"
        onClick={() => {
          handleMainMenuClick();
          if (isShowOverlay) {
            dispartch(hideOverlay());
          } else {
            dispartch(showOverlay());
          }
        }}
      >
        <div className="relative w-[30px] h-[28px]">
          <div
            className={`w-[30px] h-[3px] absolute duration-300 ${
              showMainMenu ? "rotate-45 top-[45%]" : "top-[5%]"
            } rounded-full bg-header-text`}
          ></div>
          <div
            className={`w-[30px] h-[3px] absolute top-[45%] ${
              showMainMenu ? "hidden" : ""
            } rounded-full bg-header-text`}
          ></div>
          <div
            className={`w-[30px] h-[3px] absolute duration-300 ${
              showMainMenu ? "-rotate-45 top-[45%]" : "top-[85%]"
            } rounded-full bg-header-text`}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default UniversityHeader;

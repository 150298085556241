import React, { useEffect, useState } from "react";
import { FaCheck } from "react-icons/fa6";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../loading";
import { formatDay } from "../../utils/utils";
import { acceptStudent, rejectStudent } from "../../api/registerApi";
import { toast } from "react-toastify";
import { asyncUserInformation } from "../../utils/asyncReduxUtils";
import { confirmAlert } from "../react-confirm-alert/lib";

const Dashboard = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const company = useSelector((state) => state.userInformationReducer.company);

    const [listRegistered, setListRegistered] = useState([]);
    const [listAccepted, setListAccepted] = useState([]);

    const [registeredSortBy, setRegisteredSortBy] = useState('registeredAtIncrease');
    const [acceptedSortBy, setAcceptedSortBy] = useState('acceptedAtIncrease');

    const handleAcceptStudent = (studentId) => {
        confirmAlert({
            title: "",
            message: t("notify.are_you_sure_to_accept_this_student"),
            buttons: [
                {
                    label: t("notify.yes"),
                    onClick: () => {
                        acceptStudent(studentId).then((response) => {
                            toast.success(t('notify.accept_success'), {});
                            asyncUserInformation(dispatch);
                        }).catch((error) => {
                            toast.error(error.response.data.error.message, {});
                        });
                    },
                },
                {
                    label: t("notify.no"),
                    onClick: () => { },
                },
            ],
        });
    }

    const handleRejectStudent = (studentId) => {
        confirmAlert({
            title: "",
            message: t('notify.are_you_sure_to_reject_this_student'),
            buttons: [
                {
                    label: t('notify.yes'),
                    onClick: () => {
                        rejectStudent(studentId).then((response) => {
                            toast.success(t('nofity.reject_success'), {});
                            asyncUserInformation(dispatch);
                        }).catch((error) => {
                            toast.error(error.response.data.error.message, {});
                        });
                    },
                },
                {
                    label: t('notify.no'),
                    onClick: () => { },
                },
            ],
        });
    }

    useEffect(() => {
        let list = company.registerInternships.filter((item) => item.status === 'waiting');

        if (registeredSortBy === 'registeredAtIncrease') {
            list = list.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

        } else if (registeredSortBy === 'registeredAtDecrease') {
            list = list.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        }
        setListRegistered(list);

    }, [company, registeredSortBy]);

    useEffect(() => {
        let list = company.registerInternships.filter((item) => item.status === 'accepted');

        if (acceptedSortBy === 'registeredAtIncrease') {
            list = list.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
        } else if (acceptedSortBy === 'registeredAtDecrease') {
            list = list.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        } else if (acceptedSortBy === 'acceptedAtIncrease') {
            list = list.sort((a, b) => new Date(a.updatedAt) - new Date(b.updatedAt));
        } else if (acceptedSortBy === 'acceptedAtDecrease') {
            list = list.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
        }
        setListAccepted(list);

    }, [company, acceptedSortBy]);

    return (
        <div className="min-w-full w-fit flex-grow bg-black-tertiary p-3 overflow-auto">
            <div className="w-full h-fit p-5 bg-white-primary rounded-md shadow-md shadow-white-tertiary mb-7">
                <div className="w-full h-fit flex flex-row justify-between items-center mb-3">
                    <div>
                        <span className="text-lg font-semibold">
                            {t("company.list_of_registered_students")}
                        </span>
                    </div>
                    <div className="w-fit h-full text-sm flex flex-row justify-center items-center gap-3 shadow-sm px-3 py-1 rounded-md border-[1px] border-white-secondary">
                        <span className="text-primary font-semibold">{t('company.sort')}</span>
                        <div className="w-[1px] h-[20px] bg-white-tertiary"></div>
                        <select className="bg-transparent cursor-pointer w-fit h-full" onChange={(e) => setRegisteredSortBy(e.currentTarget.value)}>
                            <option value="registeredAtIncrease">{t('company.registered_at')} ↑</option>
                            <option value="registeredAtDecrease">{t('company.registered_at')} ↓</option>
                        </select>
                    </div>
                </div>
                <div>
                    <table className="min-w-full bg-white border border-white-tertiary">
                        <thead>
                            <tr className="w-full border text-center">
                                <th className="py-3 w-[40px] px-6 font-semibold border">#</th>
                                <th className="py-3 px-6 font-semibold border">
                                    {t("company.name")}
                                </th>
                                <th className="py-3 w-[180px] px-6 font-semibold border">
                                    {t("company.student_id")}
                                </th>
                                <th className="py-3 px-6 font-semibold border">
                                    {t("company.email")}
                                </th>
                                <th className="py-3 w-[200px] px-6 font-semibold border">
                                    {t("company.phone_number")}
                                </th>
                                <th className="py-3 w-[190px] px-6 font-semibold border">
                                    {t('company.registered_at')}
                                </th>
                                <th className="py-3 w-[350px] px-6 font-semibold border">
                                    {t("company.action")}
                                </th>
                            </tr>
                        </thead>
                        {listRegistered && <tbody>
                            {listRegistered.map((item, index) => (
                                <tr key={item.id} className="hover:bg-white-secondary text-sm">
                                    <td className="py-3 text-center px-4 border">{index + 1}</td>
                                    <td className="py-3 px-4 border">{item.student.name}</td>
                                    <td className="py-3 text-center px-4 border">
                                        {item.student.organizationId}
                                    </td>
                                    <td className="py-3 px-4 border">{item.student.email}</td>
                                    <td className="py-3 px-4 border">{item.student.phoneNumber}</td>
                                    <td className="py-3 px-4 border">{formatDay(item.createdAt)}</td>
                                    <td className="py-3 text-center px-4 border ">
                                        <div className="w-full h-fit flex flex-row justify-center items-center gap-2">
                                            <div className="w-fit h-fit flex flex-row justify-center items-center gap-2 bg-primary px-4 py-3 rounded-md shadow-md text-white-primary font-bold text-md cursor-pointer hover:bg-primary-hover truncate" onClick={() => handleAcceptStudent(item.student.id)}>
                                                <FaCheck />
                                                <span>{t("company.accept_student")}</span>
                                            </div>
                                            <div className="w-fit h-fit flex flex-row justify-center items-center gap-2 bg-[#dc3545] px-4 py-3 rounded-md shadow-md text-white-primary font-bold text-md cursor-pointer hover:bg-[#bc3545]" onClick={() => handleRejectStudent(item.student.id)}>
                                                <IoIosCloseCircleOutline />
                                                <span>{t("company.reject")}</span>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>}
                    </table>
                    {!listRegistered && <div className="w-full h-fit flex justify-center items-center p-3 text-center"><Loading /></div>}
                </div>
            </div>

            <div className="min-w-full w-fit h-fit p-5 bg-white-primary rounded-md shadow-md shadow-white-tertiary mb-7">
                <div className="w-full h-fit flex flex-row justify-between items-center mb-3">
                    <div>
                        <span className="text-lg font-semibold">
                            {t("company.list_of_accepted_students")}
                        </span>
                    </div>
                    <div className="w-fit h-full text-sm flex flex-row justify-center items-center gap-3 shadow-sm px-3 py-1 rounded-md border-[1px] border-white-secondary">
                        <span className="text-primary font-semibold">{t('company.sort')}</span>
                        <div className="w-[1px] h-[20px] bg-white-tertiary"></div>
                        <select className="bg-transparent cursor-pointer w-fit h-full" onChange={(e) => setAcceptedSortBy(e.currentTarget.value)}>
                            <option value="acceptedAtIncrease">{t('company.accepted_at')} ↑</option>
                            <option value="acceptedAtDecrease">{t('company.accepted_at')} ↓</option>
                            <option value="registeredAtIncrease">{t('company.registered_at')} ↑</option>
                            <option value="registeredAtDecrease">{t('company.registered_at')} ↓</option>
                        </select>
                    </div>
                </div>
                <div>
                    <table className="min-w-full bg-white border border-white-tertiary">
                        <thead>
                            <tr className="w-full border text-center">
                                <th className="py-3 w-[40px] px-6 font-semibold border">#</th>
                                <th className="py-3 px-6 font-semibold border">
                                    {t("company.name")}
                                </th>
                                <th className="py-3 px-6 font-semibold border">
                                    {t("company.student_id")}
                                </th>
                                <th className="py-3 px-6 font-semibold border">
                                    {t("company.email")}
                                </th>
                                <th className="py-3 px-6 font-semibold border">
                                    {t("company.phone_number")}
                                </th>
                                <th className="py-3 px-6 font-semibold border">
                                    {t('company.registered_at')}
                                </th>
                                <th className="py-3 px-6 font-semibold border">
                                    {t('company.accepted_at')}
                                </th>
                            </tr>
                        </thead>
                        {listAccepted && <tbody>
                            {listAccepted.map((item, index) => (
                                <tr key={item.id} className="hover:bg-white-secondary text-sm">
                                    <td className="py-3 text-center px-4 border">{index + 1}</td>
                                    <td className="py-3 px-4 border">{item.student.name}</td>
                                    <td className="py-3 text-center px-4 border">
                                        {item.student.organizationId}
                                    </td>
                                    <td className="py-3 px-4 border">{item.student.email}</td>
                                    <td className="py-3 text-center px-4 border">{item.student.phoneNumber}</td>
                                    <td className="py-3 text-center px-4 border">{formatDay(item.createdAt)}</td>
                                    <td className="py-3 text-center px-4 border">{formatDay(item.updatedAt)}</td>
                                </tr>
                            ))}
                        </tbody>}
                    </table>
                    {!listAccepted && <div className="w-full h-fit flex justify-center items-center p-3 text-center"><Loading /></div>}
                </div>
            </div>
        </div>
    );
};

export default Dashboard;

async function downloadFile(fileUrl, fileName) {
  try {
    const response = await fetch(fileUrl);
    const blob = await response.blob();

    const downloadUrl = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = downloadUrl;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    URL.revokeObjectURL(downloadUrl);
  } catch (error) {
    console.error("Download error", error);
  }
}

export default downloadFile;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    id: 0,
    image: 'no_image.png',
    fullname: "",
    intershipProgram: "",
    internshipFiles: [],
    maxRegister: 0,
    maxAcceptedStudent: 0,
    currentRegister: 0,
    currentAcceptedStudent: 0,
    address: "",
    phoneNumber: "",
    contactEmail: "",
    work: "",
    acceptedIntern: false,
};

const companyInformationSlice = createSlice({
    name: "companyInformation",
    initialState,
    reducers: {
        setCompanyInformation: (state, action) => {
            state.id = action.payload.id;
            state.fullname = action.payload.fullname;
            state.image = action.payload.image;
            state.internshipFiles = action.payload.internshipFiles;
            state.maxRegister = action.payload.maxRegister;
            state.maxAcceptedStudent = action.payload.maxAcceptedStudent;
            state.currentRegister = action.payload.currentRegister;
            state.currentAcceptedStudent = action.payload.currentAcceptedStudent;
            state.address = action.payload.address;
            state.phoneNumber = action.payload.phoneNumber;
            state.contactEmail = action.payload.contactEmail;
            state.work = action.payload.work;
            state.acceptedIntern = action.payload.acceptedIntern;
        },

        clearCompanyInformation: (state) => {
            state.id = 0;
            state.image = 'no_image.png';
            state.fullname = "";
            state.description = "";
            state.intershipProgram = "";
            state.internshipFiles = [];
            state.maxRegister = 0;
            state.maxAcceptedStudent = 0;
            state.currentRegister = 0;
            state.currentAcceptedStudent = 0;
            state.address = "";
            state.phoneNumber = "";
            state.contactEmail = "";
            state.work = "";
            state.acceptedIntern = false;
        },
    },
});

export const { setCompanyInformation, clearCompanyInformation } = companyInformationSlice.actions;

export default companyInformationSlice.reducer;

import React, { useEffect, useState } from "react";
import Header from "../../components/header/commonHeader";
import { useDispatch, useSelector } from "react-redux";
import { hideOverlay, showOverlay } from "../../store/common/pageOverlaySlice";
import CompanyOverview from "../../components/overview/overview";
import { useNavigate } from "react-router-dom";
import { getAllCompanies, getCompaniesWithSearch } from "../../api/companyApi";
import {
  asyncCompanyInformation,
  asyncUserInformation,
} from "../../utils/asyncReduxUtils";
const { BP2D } = require("binpackingjs");
const { Bin, Box, Packer } = BP2D;

const HomePage = () => {
  const [listCompanyShuffled, setListCompanyShuffled] = useState([]);
  const [itemSize, setItemSize] = useState({ width: 0, height: 0 });
  const [listCompany, setListCompany] = useState([]);
  const [currentColumn, setCurrentColumn] = useState(16);
  const [isShowCompanyOverview, setShowCompanyOverview] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isShowOverlay = useSelector(
    (state) => state.pageOverlayReducer.showOverlay
  );

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  const shuffleArrayWithPacking = (array, binWidth, binHeight) => {
    const shuffledArray = shuffleArray([...array]);
    const bin = new Bin(binWidth, binHeight);
    const boxes = shuffledArray.map(
      (item) => new Box(item.width, item.height, item.id)
    );

    const packer = new Packer([bin]);
    packer.pack(boxes);

    // Array of packed boxes
    const results = boxes.map((box) => {
      const originalItem = shuffledArray.find(
        (item) => item.id === box.constrainRotation
      );

      return {
        width: box.width,
        height: box.height,
        x: box.x,
        y: box.y,
        id: originalItem.id,
        isSponsor: originalItem.isSponsor,
        image: originalItem.image,
        shortname: originalItem.shortname,
      };
    });

    return results;
  };

  const handleCompanyOverview = (id) => {
    asyncCompanyInformation(dispatch, id);
  };

  const handleSearchAction = (searchText, filter) => {
    if (searchText === "") {
      getAllCompanies().then((response) => {
        setListCompany(response);
      });
    } else {
      getCompaniesWithSearch(searchText, filter).then((response) => {
        setListCompany(response);
      });
    }
  };

  useEffect(() => {
    asyncUserInformation(dispatch);

    getAllCompanies().then((response) => {
      setListCompany(response);
    });

    const handleResize = () => {
      let numberColumn;
      if (window.innerWidth < 640) {
        numberColumn = 8;
        setCurrentColumn(8);
      } else {
        if (window.innerWidth < 768) {
          numberColumn = 10;
          setCurrentColumn(10);
        } else {
          if (window.innerWidth < 1024) {
            numberColumn = 12;
            setCurrentColumn(12);
          } else {
            if (window.innerWidth < 1280) {
              numberColumn = 14;
              setCurrentColumn(14);
            } else {
              numberColumn = 16;
              setCurrentColumn(16);
            }
          }
        }
      }

      const gridWidth = document.querySelector(".grid-container").offsetWidth;
      const itemWidth = (gridWidth - 10) / numberColumn;
      setItemSize({ width: itemWidth, height: itemWidth });
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [dispatch]);

  useEffect(() => {
    setListCompanyShuffled(
      shuffleArrayWithPacking(listCompany, currentColumn, 512)
    );
  }, [listCompany, itemSize]);

  useEffect(() => {
    if (!isShowOverlay) {
      setShowCompanyOverview(false);
    }
  }, [isShowOverlay]);

  return (
    <div className="w-full h-fit min-h-screen bg-white-primary flex flex-col justify-center items-center relative">
      <div className="w-full z-10">
        <Header handleSearchAction={handleSearchAction} />
      </div>

      <div className="w-full flex-grow overflow-x-hidden">
        <div className="grid-container p-[5px] w-full max-h-[calc(100vh-70px)] h-fit grid xl:grid-cols-16 lg:grid-cols-14 md:grid-cols-12 sm:grid-cols-10 grid-cols-8 overflow-y-auto overflow-x-hidden">
          {listCompanyShuffled.map((company, index) => (
            <div
              key={index}
              style={{
                gridColumn: `span ${company.width}`,
                gridRow: `span ${company.height}`,
                width: `${itemSize.width * company.width}px`,
                height: `${itemSize.height * company.height}px`,
              }}
            >
              <div className="w-full h-full p-[2px]">
                <div className="w-full h-full p-[3px] shadow-sm border-[0.5px] border-slate-300 flex justify-center items-center relative overflow-hidden group">
                  <img
                    className="object-contain w-full h-full cursor-pointer hover:scale-[1.05] duration-200"
                    src={
                      company.image
                        ? company.image
                        : 'no_image.png'
                    }
                    alt=""
                    onClick={() => {
                      handleCompanyOverview(company.id);
                      if (company.isSponsor) {
                        navigate("/sponsor");
                      } else {
                        setShowCompanyOverview(true);
                        dispatch(showOverlay());
                      }
                    }}
                  />
                  <span className="absolute bottom-[3px] right-[3px] bg-white-primary text-black-primary text-[12px] px-3 shadow-md rounded-[2px] translate-x-[120%] lg:block hidden group-hover:translate-x-0 origin-right duration-200">
                    {company.shortname}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {isShowOverlay && (
        <div
          className={`w-full h-full absolute opacity-70 bg-transparent`}
          onClick={() => dispatch(hideOverlay())}
        />
      )}

      <div
        className={`lg:w-[70%] w-[97%] h-[90%] bg-white-primary absolute z-20 top-20 origin-top duration-300 rounded-md ${isShowCompanyOverview ? "scale-100" : "scale-0"
          }`}
      >
        <CompanyOverview />
      </div>
    </div>
  );
};

export default HomePage;

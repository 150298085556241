import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import FileUpload from "../upload/image";
import { axiosWithJWT } from "../../config/axiosConfig";
import { toast } from "react-toastify";
import { getUserInformation, updateUserInformation } from "../../api/userApi";
import { changeUserInformationRedux } from "../../utils/asyncReduxUtils";
import { uploadFile } from "../../api/uploadFile";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, isValidateEmail } from "../../utils/utils";
import { confirmAlert } from "../react-confirm-alert/lib";

const Profile = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const currentUser = useSelector((state) => state.userInformationReducer);

  const [name, setName] = useState(currentUser.name);
  const [isChangePassword, setIsChangePassword] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [newUploadImage, setNewUploadImage] = useState(null);

  const handleOnClick = () => {
    if (isEmpty(name)) {
      toast.error(t("notify.empty_fields"));
      return;
    }
    // Check if change password is enabled
    if (isChangePassword) {
      if (
        isEmpty(currentPassword) ||
        isEmpty(newPassword) ||
        isEmpty(confirmPassword)
      ) {
        toast.error(t("notify.empty_fields"));
        return;
      }
      if (newPassword !== confirmPassword) {
        toast.error(t("notify.password_not_match"));
        return;
      }
    }

    confirmAlert({
      title: "",
      message: t("notify.are_you_sure_you_want_to_update"),
      buttons: [
        {
          label: t("notify.yes"),
          onClick: () => {
            handleSaveChanges();
          },
        },
        {
          label: t("notify.no"),
          onClick: () => {},
        },
      ],
    });
  };

  const handleSaveChanges = async () => {
    try {
      if (newUploadImage) {
        await handleImageUpload(newUploadImage);
      }

      if (isChangePassword) {
        await handleChangePassword(
          currentPassword,
          newPassword,
          confirmPassword
        );
      }

      await updateUserInformation(
        {
          name: name,
        },
        currentUser.id
      );

      const userInfo = await getUserInformation();

      changeUserInformationRedux(dispatch, userInfo);
      toast.success(t("notify.update_success"));
    } catch (error) {
      toast.error(
        t("notify.update_failed " + error.response.data.error.message)
      );
    }
  };

  const handleImageUpload = async (file) => {
    try {
      const response = await uploadFile(
        "plugin::users-permissions.user",
        currentUser.id,
        "image",
        file
      );

      if (response && response.length > 0) {
        const newImage = {
          original: response[0].url || "",
          thumbnail: response[0].formats?.thumbnail?.url || "",
          small: response[0].formats?.small?.url || "",
          medium: response[0].formats?.medium?.url || "",
          large: response[0].formats?.large?.url || "",
        };
        return newImage;
      } else {
        toast.error(t("notify.upload_failed"));
        return null;
      }
    } catch (error) {
      toast.error(t("notify.upload_failed"));
      return null;
    }
  };

  const handleFileChange = (file) => {
    setNewUploadImage(file);
  };

  const handleChangePassword = async () => {
    if (newPassword !== confirmPassword) {
      toast.error("New password and confirm password do not match!");
      return;
    }

    try {
      await axiosWithJWT.post("/api/auth/change-password", {
        currentPassword: currentPassword,
        password: newPassword,
        passwordConfirmation: confirmPassword,
      });
    } catch (error) {
      toast.error(error.response.data.error.message);
    }
  };

  return (
    <div className="w-full flex-grow bg-black-tertiary p-3 overflow-auto">
      <div className="w-full h-fit min-h-full flex flex-col justify-start items-center bg-white-primary shadow-md p-5 rounded-md gap-2">
        <div className="lg:w-[700px] w-full h-full flex flex-col justify-start items-center relative xl:p-3 p-0 rounded-md xl:shadow-md shadow-none shadow-white-tertiary">
          <span className="w-full text-left font-semibold text-lg">
            {t("university.avatar")}
          </span>
          <div className="py-3 px-5 w-full h-fit flex flex-col justify-end items-center">
            <FileUpload
              currentImageUrl={currentUser.image.original}
              onImageUpload={handleFileChange}
            />
          </div>

          <span className="w-full text-left font-semibold text-lg">
            {t("university.account_information")}
          </span>
          <div className="flex flex-col justify-center items-start w-full py-3 px-5 text-[15px]">
            <span>{t("university.name")}</span>
            <input
              type="text"
              placeholder={t("university.name")}
              className="mt-1 mb-3 w-full border border-white-secondary focus:border-white-tertiary outline-none py-[7px] px-4 rounded-md shadow-sm"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />

            <span>{t("university.email")}</span>
            <input
              type="text"
              placeholder={t("university.email")}
              value={currentUser.email}
              disabled
              className="mt-1 mb-3 w-full border border-white-secondary focus:border-white-tertiary outline-none py-[7px] px-4 rounded-md shadow-sm text-white-tertiary bg-white-secondary"
            />
            <div className="w-full h-fit flex flex-row justify-between items-center">
              <span
                className={`w-fit h-fit py-3 px-5 bg-primary text-white font-semibold rounded-md shadow-md hover:bg-primary-hover cursor-pointer mt-1 mb-3 transition truncate ${
                  !isChangePassword ? "scale-100" : "scale-0"
                }`}
                onClick={() => setIsChangePassword(true)}
              >
                {t("university.change_password")}
              </span>
              <span
                className={`sm:w-fit w-[100px] h-fit py-3 px-5 bg-[#707070] text-white font-semibold rounded-md shadow-md hover:bg-black-secondary cursor-pointer mt-1 mb-3 transition truncate ${
                  isChangePassword ? "scale-100" : "scale-0"
                }`}
                onClick={() => setIsChangePassword(false)}
              >
                {t("university.close_changes")}
              </span>
            </div>
            {isChangePassword && (
              <div className="w-full">
                <span className="w-full text-left font-semibold text-lg absolute left-3">
                  {t("university.change_password")}
                </span>

                <div className="mt-8">
                  <span>{t("university.current_password")}</span>
                  <input
                    type="password"
                    placeholder={t("university.current_password")}
                    className="mt-1 mb-5 w-full border border-white-secondary focus:border-white-tertiary outline-none py-[7px] px-4 rounded-md shadow-sm"
                    onChange={(e) => setCurrentPassword(e.target.value)}
                  />
                </div>

                <div>
                  <span>{t("university.new_password")}</span>
                  <input
                    type="password"
                    placeholder={t("university.new_password")}
                    className="mt-1 mb-3 w-full border border-white-secondary focus:border-white-tertiary outline-none py-[7px] px-4 rounded-md shadow-sm"
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                </div>

                <div>
                  <span>{t("university.confirm_password")}</span>
                  <input
                    type="password"
                    placeholder={t("university.confirm_password")}
                    className="mt-1 mb-3 w-full border border-white-secondary focus:border-white-tertiary outline-none py-[7px] px-4 rounded-md shadow-sm"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </div>
              </div>
            )}
          </div>
          <span
            className="w-fit h-fit py-3 px-5 bg-primary text-white font-semibold rounded-md shadow-md hover:bg-primary-hover cursor-pointer mt-5 mb-10"
            onClick={handleOnClick}
          >
            {t("university.save_changes")}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Profile;

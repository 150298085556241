import React, { useRef } from 'react';
import './style.css';

const NotFound = () => {
  const textElementRef = useRef(null);

  // useEffect(() => {
  //   const textElement = textElementRef.current;

  //   const handleMouseMove = (event) => {

  //     const boundingRect = textElement.getBoundingClientRect();
  //     const relativeX = event.clientX - boundingRect.left;
  //     const relativeY = event.clientY - boundingRect.top;

  //     const normalizedX = relativeX / boundingRect.width;
  //     const normalizedY = relativeY / boundingRect.height;

  //     const shadowOffsetX = (normalizedX - 0.5) * 10;
  //     const shadowOffsetY = (normalizedY - 0.5) * 10;

  //     textElement.style.textShadow = `
  //       1px 1px 1px #093658,
  //       2px 2px 1px #093658,
  //       3px 3px 1px #093658,
  //       4px 4px 1px #093658,
  //       5px 5px 1px #093658,
  //       6px 6px 1px #093658,
  //       7px 7px 1px #093658,
  //       8px 8px 1px #093658,
  //       ${shadowOffsetX}px ${shadowOffsetY}px 1px #093658
  //     `;
  //   };

  //   const handleMouseLeave = () => {
  //     textElement.style.textShadow = `
  //       1px 1px 1px #093658,    
  //       2px 2px 1px #093658,
  //       3px 3px 1px #093658,
  //       4px 4px 1px #093658,
  //       5px 5px 1px #093658,
  //       6px 6px 1px #093658,
  //       7px 7px 1px #093658,
  //       8px 8px 1px #093658,
  //       25px 25px 8px rgba(0,0,0, 0.2)
  //     `;
  //   };

  //   window.addEventListener('mousemove', handleMouseMove);
  //   window.addEventListener('mouseleave', handleMouseLeave);

  //   return () => {
  //     window.removeEventListener('mousemove', handleMouseMove);
  //     window.removeEventListener('mouseleave', handleMouseLeave);
  //   };
  // }, []);

  return (
    <div className="section flex flex-col justify-center items-center">
      <h1 className="error" ref={textElementRef}>404</h1>
      <div className="page">Ooops!!! The page you are looking for is not found</div>
      <a className="back-home bg-blue-primary hover:bg-blue-secondary" href="/">Back to home</a>
    </div>
  );
};

export default NotFound;

import Cookies from "js-cookie"
import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { getUserInformation, loginWithGoogle } from "../../api/userApi"
import Loading from "../../components/loading"
import { toast } from "react-toastify";
import { changeUserInformationRedux } from "../../utils/asyncReduxUtils"
import { useDispatch } from "react-redux"

function GoogleAuthCallback() {

    const location = useLocation()
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {

        setIsLoading(true);

        if (!location) {
            setIsLoading(false);
            return
        }

        const { search } = location

        loginWithGoogle(search).then((response) => {
            Cookies.set(process.env.REACT_APP_JWT_NAME, response.jwt, {
                sameSite: 'lax',
            });

            getUserInformation().then((user) => {

                changeUserInformationRedux(dispatch, user).then(() => {
                    setIsLoading(false);
                    navigate('/');
                }).catch((error) => {
                    console.log(error);
                });
            }).catch((error) => {
                console.log(error);
            });

        }).catch((error) => {
            console.log(error);

            setIsLoading(false);
            toast.error("Tài khoản không tồn tại!", {});
            navigate('/auth');
        });

    }, [location])

    if (isLoading) {
        return (
            <div className='w-full h-screen flex justify-center items-center'>
                <Loading />
            </div>
        )
    }
}

export default GoogleAuthCallback;

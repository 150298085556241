import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import FileUpload from "../upload/image";
import { useDispatch, useSelector } from "react-redux";
import { updateUserInformation } from "../../api/userApi";
import { toast } from "react-toastify";
import { uploadFile } from "../../api/uploadFile";
import { asyncUserInformation } from "../../utils/asyncReduxUtils";
import { getUserInformation } from "../../api/userApi";
import { axiosWithJWT } from "../../config/axiosConfig";
import { IoIosCloseCircle } from "react-icons/io";
import { hideOverlay } from "../../store/common/pageOverlaySlice";
import { isEmpty } from "../../utils/utils";

const Profile = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const currentUser = useSelector((state) => state.userInformationReducer);

    const [phoneNumber, setPhoneNumber] = useState(currentUser.phoneNumber);
    const [newUploadImage, setNewUploadImage] = useState(null);

    useEffect(() => {
        if (currentUser.phoneNumber) {
            setPhoneNumber(currentUser.phoneNumber);
        }
    }, [currentUser.phoneNumber]);

    const handleSaveChanges = async () => {
        // Check if phone number is empty
        if (isEmpty(phoneNumber)) {
            toast.error("Please fill in all fields!");
            return;
        }
        // Check if phone number is invalid
        if (phoneNumber.length < 10 || phoneNumber.length > 11) {
            toast.error("Phone number is invalid!");
            return;
        }
        try {
            if (newUploadImage) {
                await handleImageUpload(newUploadImage);
            }

            updateUserInformation({ data: { phoneNumber } }, currentUser.id);

            asyncUserInformation(dispatch);
            toast.success(t("notify.update_success"));
        } catch (error) {
            toast.error(t("notify.update_failed"));
        }
    };

    const handleImageUpload = async (file) => {
        try {
            const response = await uploadFile(
                "plugin::users-permissions.user",
                currentUser.id,
                "image",
                file
            );

            if (response && response.length > 0) {
                const newImage = {
                    original: response[0].url || "",
                    thumbnail: response[0].formats?.thumbnail?.url || "",
                    small: response[0].formats?.small?.url || "",
                    medium: response[0].formats?.medium?.url || "",
                    large: response[0].formats?.large?.url || "",
                };
                return newImage;
            } else {
                toast.error(t("notify.upload_failed"));
                return null;
            }
        } catch (error) {
            toast.error(t("notify.upload_failed"));
            return null;
        }
    };

    const handleChangePassword = async (currentPassword, newPassword, confirmPassword) => {
        if (newPassword !== confirmPassword) {
            toast.error("New password and confirm password do not match!");
            return;
        }

        try {
            const response = await axiosWithJWT.post("/api/auth/change-password", {
                currentPassword: currentPassword,
                password: newPassword,
                passwordConfirmation: confirmPassword,
            });

            if (response.status === 200) {
                toast.success(t("notify.change_password_success"));
            } else {
                toast.error(t("notify.change_password_failed"));
            }
        } catch (error) {
            toast.error(t("notify.change_password_failed"));
        }
    };

    const handleFileChange = (file) => {
        setNewUploadImage(file);
    };

    return (
        <div className="w-full flex-grow bg-transparent p-3 overflow-auto text-black-secondary">
            <div className="w-full h-fit min-h-full flex flex-col justify-start items-center bg-white-primary shadow-md p-5 rounded-md gap-2 relative">
                <div
                    className="absolute scale-125 top-1 right-1 cursor-pointer"
                    onClick={() => dispatch(hideOverlay())}>
                    <IoIosCloseCircle />
                </div>

                <div className="w-full h-full flex flex-col justify-start items-center relative p-3 rounded-md">
                    <span className="w-full text-left font-semibold text-lg">
                        {t("company.avatar")}
                    </span>
                    <div className="py-3 px-5 w-full h-fit flex flex-col justify-end items-center">
                        <FileUpload
                            currentImageUrl={currentUser.image.original}
                            onImageUpload={handleFileChange}
                        />
                    </div>

                    <span className="w-full text-left font-semibold text-lg">
                        {t("company.account_information")}
                    </span>
                    <div className="flex flex-col justify-center items-start w-full py-3 px-5 text-[15px]">
                        <span>{t("company.name")}</span>
                        <input
                            type="text"
                            disabled
                            placeholder={t("company.name")}
                            className="mt-1 mb-3 w-full border border-white-secondary focus:border-white-tertiary outline-none py-[7px] px-4 rounded-md shadow-sm text-white-tertiary bg-white-secondary"
                            value={currentUser.name}
                        />

                        <span>{t("company.email")}</span>
                        <input
                            type="text"
                            disabled
                            placeholder={t("company.email")}
                            value={currentUser.email}
                            className="mt-1 mb-3 w-full border border-white-secondary focus:border-white-tertiary outline-none py-[7px] px-4 rounded-md shadow-sm text-white-tertiary bg-white-secondary"
                        />

                        <span>{t("company.phone_number")}</span>
                        <input
                            type="text"
                            placeholder={t("company.phone_number")}
                            className="mt-1 mb-3 w-full border border-white-secondary focus:border-white-tertiary outline-none py-[7px] px-4 rounded-md shadow-sm"
                            value={phoneNumber}
                            onChange={(e) => {
                                setPhoneNumber(e.target.value);
                            }}
                        />
                    </div>
                    <span
                        className="w-fit h-fit py-3 px-5 bg-primary text-white font-semibold rounded-md shadow-md hover:bg-blue-secondary cursor-pointer mt-5 mb-10"
                        onClick={handleSaveChanges}>
                        {t("company.save_changes")}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default Profile;

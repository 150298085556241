import React, { useEffect, useRef, useState } from "react";
import { AiOutlineCaretDown } from "react-icons/ai";
import { CiSearch } from "react-icons/ci";
import DropdownMenu from "../menu/dropdownMenu";
import { IoMdLogOut } from "react-icons/io";
import { GrLanguage } from "react-icons/gr";
import { IoBusinessOutline } from "react-icons/io5";
import { RiLogoutCircleLine } from "react-icons/ri";
import MenuItem from "../menu/menuItem";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FaCheck } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { hideOverlay, showOverlay } from "../../store/common/pageOverlaySlice";
import { IoCloseCircle } from "react-icons/io5";
import { DiGoogleAnalytics } from "react-icons/di";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { logoutUser } from "../../store/common/userInformationSlice";
import { ImProfile } from "react-icons/im";
import { FaListUl } from "react-icons/fa";
import Profile from "../student/profile";
import RegisteredList from "../student/registeredList";
import { getStatistic } from "../../api/statisticApi";
import SchoolIcon from "@mui/icons-material/School";

const Header = ({ handleSearchAction }) => {
  const theme = useSelector((state) => state.themeReducer);
  const [showMenu, setShowMenu] = useState(false);
  const [buttonAction, setButtonAction] = useState(false);

  const [showMenuLanguage, setShowMenuLanguag] = useState(false);
  const [showMainMenu, setShowMainMenu] = useState(false);

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);

  const [isEditProfile, setIsEditProfile] = useState(false);
  const [isViewRegisteredList, setIsViewRegisteredList] = useState(false);

  const isShowOverlay = useSelector(
    (state) => state.pageOverlayReducer.showOverlay
  );
  const dispartch = useDispatch();
  const currentUser = useSelector((state) => state.userInformationReducer);

  const [statistics, setStatistics] = useState({
    totalStudent: 0,
    acceptedStudent: 0,
    applyingStudent: 0,
  });
  const [searchText, setSearchText] = useState("");
  const filter = useRef();

  const handleMainMenuClick = () => {
    setShowMainMenu(!showMainMenu);
  };

  const handleChangeLanguage = (language) => {
    setCurrentLanguage(language);
    i18n.changeLanguage(language);
  };

  const handleLogout = () => {
    Cookies.remove(process.env.REACT_APP_JWT_NAME, { sameSite: "lax" });
    dispartch(logoutUser());
    toast.success(t("notify.logout_success"));
    navigate("/");
  };

  useEffect(() => {
    getStatistic().then((response) => {
      setStatistics(response.data);
    });
  }, []);

  useEffect(() => {
    if (!isShowOverlay) {
      setShowMainMenu(false);
      setShowMenu(false);
      setShowMenuLanguag(false);
      setIsEditProfile(false);
      setIsViewRegisteredList(false);
    }
  }, [isShowOverlay]);

  return (
    <div
      className={`w-full h-[70px] bg-primary text-header-text flex flex-row justify-between items-center relative`}
    >
      <div
        className={`absolute md:w-[700px] w-full h-fit left-1/2 -translate-x-1/2 top-full z-30 duration-300 origin-top ${isEditProfile ? "" : "scale-0"
          }`}
      >
        <Profile />
      </div>
      <div
        className={`absolute w-[70%] min-w-[1000px] h-fit left-1/2 -translate-x-1/2 top-full z-30 duration-300 origin-top ${isViewRegisteredList ? "" : "scale-0"
          }`}
      >
        <RegisteredList />
      </div>
      <div className="w-fit h-full flex flex-row justify-start items-center flex-grow gap-3 px-5">
        <img
          className="min-w-[55px] min-h-[55px] w-[55px] h-[55px] bg-white rounded-full cursor-pointer"
          src={theme.logoUrl ? theme.logoUrl : 'no_image.png'}
          alt=""
          onClick={() => navigate("/")}
        />
        <span className="text-3xl font-bold ml-1">{theme.websiteName}</span>
      </div>

      <div
        className={`w-[350px] lg:w-fit h-fit flex lg:px-0 lg:py-0 px-2 py-5 lg:flex-row flex-col-reverse lg:bg-transparent bg-primary lg:static absolute top-[75px] right-1 justify-center items-center gap-5 rounded-md lg:rounded-none z-10 origin-top-right duration-200 ${showMainMenu && window.innerWidth < 1024
          ? "scale-100"
          : window.innerWidth > 1024
            ? "scale-100"
            : "scale-0"
          }`}
      >
        <div
          className="h-12 lg:h-[70px] lg:w-fit w-full px-3 flex flex-row justify-start items-center text-[17px] gap-2 cursor-pointer hover:bg-primary-hover relative"
          onClick={() => {
            setShowMenu(!showMenu);
            setButtonAction("statistical");
            if (!showMainMenu) {
              dispartch(isShowOverlay ? hideOverlay() : showOverlay());
            }
          }}
        >
          <div className="scale-125 px-2">
            <DiGoogleAnalytics />
          </div>
          <span className="flex-grow min-w-[150px]">
            {t("header.statistics")}
          </span>
          {showMenu && buttonAction === "statistical" ? (
            <div onClick={() => dispartch(hideOverlay())}>
              <IoCloseCircle />
            </div>
          ) : (
            <AiOutlineCaretDown />
          )}

          <div
            className={`absolute top-[140%] lg:right-1/2 lg:translate-x-1/2 right-0 lg:top-full duration-300 origin-top ${showMenu && buttonAction === "statistical"
              ? "scale-100"
              : "scale-0"
              }`}
          >
            <DropdownMenu
              listMenuItems={[
                <MenuItem
                  menuContext={t("header.total_number_of_student")}
                  otherComponent={<span>{statistics.totalStudent}</span>}
                />,
                <MenuItem
                  menuContext={t("header.students_have_been_accepted")}
                  otherComponent={<span>{statistics.acceptedStudent}</span>}
                />,
                <MenuItem
                  menuContext={t("header.students_are_applying_for_interships")}
                  otherComponent={<span>{statistics.applyingStudent}</span>}
                />,
              ]}
              width="lg:w-[380px] w-[340px]"
            />
          </div>
        </div>

        <div className="flex flex-row items-center mx-3 pl-7 pr-2 gap-3 shadow-md border-header-text border-[1px] rounded-full w-full h-[35px] text-[14px] overflow-hidden lg:overflow-visible">
          <input
            className="outline-none bg-transparent placeholder-header-text flex-grow"
            placeholder={t("header.search_company")}
            type="text"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSearchAction(searchText, filter.current.value);
              }
            }}
          />
          <div
            className="w-fit h-fit p-[5px] rounded-full hover:bg-white-secondary hover:text-black-primary"
            onClick={() => handleSearchAction(searchText, filter.current.value)}
          >
            <CiSearch />
          </div>
          <div className="flex flex-row gap-3 justify-center items-center">
            <div className="w-[1px] h-[20px] bg-header-text"></div>
            <select ref={filter} className="bg-transparent cursor-pointer">
              <option value="all">{t("header.all")}</option>
              <option value="hiring">{t("header.hiring")}</option>
              <option value="closed">{t("header.closed")}</option>
            </select>
          </div>
        </div>

        <div
          className="w-full lg:h-[70px] h-12 px-4 gap-2 cursor-pointer flex flex-row justify-start items-center hover:bg-primary-hover relative"
          onClick={() => {
            setShowMenu(!showMenu);
            setButtonAction("account_options");
            if (!showMainMenu) {
              dispartch(isShowOverlay ? hideOverlay() : showOverlay());
            }
          }}
        >
          <img
            src={
              currentUser.image.thumbnail
                ? currentUser.image.thumbnail
                : currentUser.image.original
                  ? currentUser.image.original
                  : 'no_user.png'
            }
            className="w-[40px] h-[40px] bg-white-primary rounded-full p-[1px] object-contain"
            alt=""
          />
          <span className="flex-grow truncate">
            {currentUser.name !== ""
              ? currentUser.name
              : t("header.user_account")}
          </span>
          {showMenu && buttonAction === "account_options" ? (
            <div onClick={() => dispartch(hideOverlay())}>
              <IoCloseCircle />
            </div>
          ) : (
            <AiOutlineCaretDown />
          )}

          <div
            className={`absolute top-full right-0 md:right-[103%] duration-300 origin-top lg:right-1 ${showMenu && buttonAction === "account_options"
              ? "scale-100"
              : "scale-0"
              }`}
          >
            <DropdownMenu
              listMenuItems={[
                !currentUser.isAuthenticated && (
                  <MenuItem
                    menuIcon={<IoMdLogOut />}
                    menuContext={t("header.login")}
                    onClick={() => {
                      navigate("/auth");
                      dispartch(hideOverlay());
                    }}
                  />
                ),
                currentUser.isAuthenticated &&
                currentUser.role === "Company" && (
                  <MenuItem
                    menuIcon={<IoBusinessOutline />}
                    menuContext={t("header.company")}
                    onClick={() => {
                      navigate("/company");
                    }}
                  />
                ),
                currentUser.isAuthenticated && currentUser.role === "Admin" && (
                  <MenuItem
                    menuIcon={<SchoolIcon />}
                    menuContext={t("header.university")}
                    onClick={() => {
                      navigate("/university");
                    }}
                  />
                ),
                currentUser.isAuthenticated &&
                currentUser.role === "Student" && (
                  <MenuItem
                    menuIcon={<ImProfile />}
                    menuContext={t("header.profile")}
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowMenu(false);
                      setShowMainMenu(false);

                      setIsEditProfile(true);
                      dispartch(showOverlay());
                    }}
                  />
                ),
                currentUser.isAuthenticated &&
                currentUser.role === "Student" && (
                  <MenuItem
                    menuIcon={<FaListUl />}
                    menuContext={t("header.registered_list")}
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowMenu(false);
                      setShowMainMenu(false);

                      setIsViewRegisteredList(true);
                      dispartch(showOverlay());
                    }}
                  />
                ),
                <div
                  className={`relative`}
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowMenuLanguag(!showMenuLanguage);
                  }}
                >
                  <MenuItem
                    menuIcon={<GrLanguage />}
                    menuContext={t("header.language")}
                    otherComponent={
                      showMenuLanguage ? (
                        <div onClick={() => dispartch(hideOverlay())}>
                          <IoCloseCircle />
                        </div>
                      ) : (
                        <AiOutlineCaretDown />
                      )
                    }
                  />
                  <div
                    className={`absolute top-full duration-300 right-0 origin-top ${showMenuLanguage ? "scale-100" : "scale-0"
                      }`}
                  >
                    <DropdownMenu
                      listMenuItems={[
                        <MenuItem
                          menuContext="English"
                          onClick={() => {
                            handleChangeLanguage("en");
                            dispartch(hideOverlay());
                          }}
                          otherComponent={
                            currentLanguage === "en" ? <FaCheck /> : ""
                          }
                        />,
                        <MenuItem
                          menuContext="Vietnamese"
                          onClick={() => {
                            handleChangeLanguage("vi");
                            dispartch(hideOverlay());
                          }}
                          otherComponent={
                            currentLanguage === "vi" ? <FaCheck /> : ""
                          }
                        />,
                      ]}
                      width="w-[190px]"
                    />
                  </div>
                </div>,
                currentUser.isAuthenticated && (
                  <MenuItem
                    menuIcon={<RiLogoutCircleLine />}
                    menuContext={t("header.logout")}
                    onClick={handleLogout}
                  />
                ),
              ]}
              width="w-[300px]"
            />
          </div>
        </div>
      </div>

      <div
        className="w-fit h-fit p-2 hover:bg-primary-hover cursor-pointer rounded-sm mx-3 lg:hidden"
        onClick={() => {
          handleMainMenuClick();
          if (isShowOverlay) {
            dispartch(hideOverlay());
          } else {
            dispartch(showOverlay());
          }
        }}
      >
        <div className="relative w-[30px] h-[28px]">
          <div
            className={`w-[30px] h-[3px] absolute duration-300 ${showMainMenu ? "rotate-45 top-[45%]" : "top-[5%]"
              } rounded-full bg-header-text`}
          ></div>
          <div
            className={`w-[30px] h-[3px] absolute top-[45%] ${showMainMenu ? "hidden" : ""
              } rounded-full bg-header-text`}
          ></div>
          <div
            className={`w-[30px] h-[3px] absolute duration-300 ${showMainMenu ? "-rotate-45 top-[45%]" : "top-[85%]"
              } rounded-full bg-header-text`}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default Header;

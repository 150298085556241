import React from "react";
import { Routes, Route } from "react-router-dom";
import HomePage from "../pages/home";
import AuthPage from "../pages/auth";
import CompanyPage from "../pages/company";
import UniversityPage from "../pages/university";
import SponsorPage from "../pages/sponsor";
import GoogleAuthCallback from "../pages/auth/googleAuth";
import ProtectedRoute from "./ProtectedRoute";
import NotFoundPage from "../pages/notFound";

function AppRoutes() {
    return (
        <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/auth" element={<AuthPage />} />
            <Route
                path="/university"
                element={
                    <ProtectedRoute allowedRole="Admin">
                        <UniversityPage />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/company"
                element={
                    <ProtectedRoute allowedRole="Company">
                        <CompanyPage />
                    </ProtectedRoute>
                }
            />
            <Route path="/sponsor" element={<SponsorPage />} />
            <Route path="/auth/google/callback" element={<GoogleAuthCallback />} />
            <Route path="/404" element={<NotFoundPage />} />
            <Route path="*" element={<NotFoundPage />} />
        </Routes>
    );
}

export default AppRoutes;

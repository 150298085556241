import React from "react";
import { handleRequestEmail } from "../../api/userApi";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const ConfirmEmail = ({ email }) => {
  const { t } = useTranslation();

  const handleResentEmail = () => {
    toast.success(t("notify.resent_email_will_sent"), {});
    handleRequestEmail({
      email: email,
    });
  };
  return (
    <div className="w-fit h-fit p-5 rounded-md shadow-md bg-white-primary flex flex-col justify-center items-center gap-4">
      <span className="font-bold text-blue-primary text-lg">
        {t("confirm_email.notification_title")}
      </span>
      <div className="w-full h-[0.5px] bg-white-secondary"></div>
      <div className="flex flex-col justify-center items-center gap-2">
        <div className="text-center">
          <p>{t("confirm_email.sent_to")}</p>
          <p className="italic text-blue-primary">{email}</p>
        </div>
        <div className="flex flex-col justify-center items-center mt-4">
          <p>{t("confirm_email.confirm_message")}</p>
          <p>
            {t("confirm_email.spam_check")}
          </p>
        </div>
      </div>
      <div className="w-full h-[1px] bg-white-secondary"></div>
      <div
        className="w-fit h-fit px-10 py-2 bg-[#0369c3] hover:bg-[#0369a3] text-center text-white-primary font-bold rounded-sm cursor-pointer"
        onClick={() => handleResentEmail()}
      >
        <span>{t("confirm_email.resend")}</span>
      </div>
    </div>
  );
};

export default ConfirmEmail;

import { toast } from "react-toastify";
import { axiosWithJWT } from "../config/axiosConfig";

// Create new company
async function createCompany(data) {
  try {
    const response = await axiosWithJWT.post(`/api/companies`, data);
    return response.data;
  } catch (error) {
    throw error;
  }
}

const updateCompany = async (companyId, updatedData) => {
  try {
    const response = await axiosWithJWT.put(
      `/api/companies/${companyId}`,
      {
        data: updatedData,
      }
    );

    return response.data;
  } catch (error) {
    toast.error(error.response.data.error.message);
    return null;
  }
};

const getAllCompanies = async () => {
  try {
    const response = await axiosWithJWT.get(`/api/companies/`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getCompaniesWithSearch = async (searchText, filter) => {
  try {
    const response = await axiosWithJWT.get(
      `/api/companies/?search=${searchText}&filter=${filter}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getCompanyById = async (companyId) => {
  try {
    const response = await axiosWithJWT.get(`/api/companies/${companyId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export {
  createCompany,
  updateCompany,
  getAllCompanies,
  getCompanyById,
  getCompaniesWithSearch,
};

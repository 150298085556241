import { axiosWithJWT, axiosWithoutJWT } from "../config/axiosConfig";

// Register new user with email and password
async function registerUser(data) {
  try {
    const response = await axiosWithoutJWT.post(`/api/users/`, data);
    return response.data;
  } catch (error) {
    throw error;
  }
}

// Login user with email and password
async function loginUser(data) {
  try {
    const response = await axiosWithoutJWT.post(`/api/auth/local/`, data);
    return response.data;
  } catch (error) {
    throw error;
  }
}

// Get user information, image and company information if available
async function getUserInformation() {
  try {
    const response = await axiosWithJWT.get(`/api/users/me/`);
    return response.data;
  } catch (error) {
    throw error;
  }
}

async function updateUserInformation(data, userId) {
  try {
    const response = await axiosWithJWT.put(`/api/users/` + userId, data);
    return response.data;
  } catch (error) {
    throw error;
  }
}

const loginWithGoogle = async (location) => {
  try {
    const response = await axiosWithoutJWT.get(
      `/api/auth/google/callback/?${location}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const registerInternship = async (companyId) => {
  try {
    const response = await axiosWithJWT.post(
      `/api/register-internships/?companyId=${companyId}`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

const handleRequestEmail = async (data) => {
  try {
    const response = await axiosWithoutJWT.post(`/api/email-requests`, {
      data: data,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export {
  registerUser,
  loginUser,
  getUserInformation,
  updateUserInformation,
  loginWithGoogle,
  registerInternship,
  handleRequestEmail,
};

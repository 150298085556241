import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    id: "",
    css: {
        primaryColor: "",
        primaryColorRGB: "",
        loginTextColor: "",
        loginTextColorRGB: "",
        headerTextColor: "",
        headerTextColorRGB: "",
        navigateTextColor: "",
        navigateTextColorRGB: "",
        loginBackgroundColor: "",
        loginBackgroundColorRGB: "",
        navigateBackgroundColor: "",
        navigateBackgroundColorRGB: "",
    },
    websiteName: "",
    logoUrl: "",
    backgroundUrl: "",
};

const themeSlice = createSlice({
    name: "theme",
    initialState,
    reducers: {
        setThemeData: (state, action) => {
            const { id, css, websiteName, logoUrl, backgroundUrl } = action.payload;
            state.id = id;
            state.css = css;
            state.websiteName = websiteName;
            state.logoUrl = logoUrl;
            state.backgroundUrl = backgroundUrl;
        },
    },
});

export const { setThemeData } = themeSlice.actions;
export default themeSlice.reducer;

import { CKEditor } from '@ckeditor/ckeditor5-react';
import {
    ClassicEditor,
    Bold,
    Essentials,
    Paragraph,
    Undo,
    Mention,
    Italic,
    SourceEditing,
    Heading,
    Underline,
    Strikethrough,
    Superscript,
    Subscript,
    FontColor,
    FontBackgroundColor,
    FontSize,
    Alignment,
    Link,
    List,
    Indent,
    IndentBlock,
    Image,
    ImageToolbar,
    ImageCaption,
    ImageStyle,
    ImageResize,
    ImageUpload,
    BlockQuote,
    Table,
    TableToolbar,
    SpecialCharacters,
    SpecialCharactersEssentials,
    FindAndReplace
} from 'ckeditor5';

import 'ckeditor5/ckeditor5.css';
import { RiSlashCommands } from 'react-icons/ri';
import { uploadFile } from '../../api/uploadFile';

export default function CKEditor5({ getDataOnChange, initialData }) {
    return (
        <CKEditor
            editor={ClassicEditor}
            data={initialData}
            config={{
                toolbar: {
                    items: [
                        'sourceEditing', 'heading', 'undo', 'redo', '|',
                        'bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript', '|',
                        'fontColor', 'fontBackgroundColor', 'fontSize', '|',
                        'alignment', 'link', 'bulletedList', 'numberedList', '|',
                        'outdent', 'indent', '|',
                        'uploadImage',
                        'blockQuote', 'insertTable', 'specialCharacters', '|',
                        'findAndReplace'
                    ],
                },
                plugins: [
                    Bold, Essentials, Italic, Mention, Paragraph, RiSlashCommands, Undo, SourceEditing, Heading,
                    Underline, Strikethrough, Superscript, Subscript, FontColor, FontBackgroundColor, FontSize,
                    Alignment, Link, List, Indent, IndentBlock, Image, ImageToolbar, ImageCaption, ImageStyle, ImageResize,
                    ImageUpload, 
                    BlockQuote, Table, TableToolbar, SpecialCharacters, SpecialCharactersEssentials, FindAndReplace
                ],
                mention: {
                },
            }}
            onReady={(editor) => {
                editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
                    return new TemporaryImageUploadAdapter(loader);
                };
            }}

            onChange={(event, editor) => {
                const data = editor.getData();
                getDataOnChange(data);
            }}
        />
    );
}


class TemporaryImageUploadAdapter {
    constructor(loader) {
        this.loader = loader;
    }

    upload() {
        return this.loader.file.then((file) => {
            return uploadFile(null, null, null, file).then((data) => {
                return {
                    default: data[0].url,
                };
            });
        });
    }

    abort() {

    }
}

export const isEmpty = (value) => {
    return value === null || value === undefined || value === '';
};

export const isValidateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailRegex.test(email);
}

export const isEnoughLength = (value, length) => {
    return value.length >= length;
}

export const formatDay = (data) => {
    const date = new Date(data);

    const formattedDate = date.toLocaleString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit"
    });
    return formattedDate;
}

export const darkenRgbColor = (rgb, n) => {
    return rgb.map(value => Math.max(0, value - n));
}

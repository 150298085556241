import React, { useState, useRef, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import TextField from "@mui/material/TextField";
import { Button, InputAdornment, Pagination } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { formatDay } from "../../utils/utils";
import FileModal from "../modal/fileModal";
import { IoMdOpen } from "react-icons/io";
import { HiOutlineFolderDownload } from "react-icons/hi";
import { confirmAlert } from "../react-confirm-alert/lib";
import downloadFile from "../download";
import { toast } from "react-toastify";

const RegisteredCompaniesTable = ({
  data,
  onAccept,
  onReject,
  internshipFiles,
}) => {
  const { t } = useTranslation();
  const [showClearIcon, setShowClearIcon] = useState("none");
  const searchInputRef = useRef(null);
  const [isAscending, setIsAscending] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [companyData, setCompanyData] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openFileModal, setOpenFileModal] = useState(false);
  const [selectedFileUrl, setSelectedFileUrl] = useState("");
  const [selectedFileType, setSelectedFileType] = useState("");

  useEffect(() => {
    setCompanyData(data);
  }, [data]);

  const handleSort = () => {
    setIsAscending(!isAscending);
  };

  const sortedData = useMemo(() => {
    return [...companyData].sort((a, b) => {
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);
      return isAscending ? dateA - dateB : dateB - dateA;
    });
  }, [companyData, isAscending]);

  const handleChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    setShowClearIcon(value === "" ? "none" : "flex");
  };

  const handleClick = () => {
    if (searchInputRef.current) {
      searchInputRef.current.value = "";
      setSearchTerm("");
      setShowClearIcon("none");
    }
  };

  const filteredData = useMemo(() => {
    const normalizedSearchTerm = searchTerm.normalize("NFD").toLowerCase();

    return sortedData.filter((item) => {
      const fullname = item.fullname || ""; // Fallback to empty string
      const email = item.contactEmail || ""; // Fallback to empty string

      const fullnameMatch = fullname
        .normalize("NFD")
        .toLowerCase()
        .includes(normalizedSearchTerm);
      const emailMatch = email
        .normalize("NFD")
        .toLowerCase()
        .includes(normalizedSearchTerm);

      return !item.isAccepted && (fullnameMatch || emailMatch);
    });
  }, [sortedData, searchTerm]);

  const totalPages = Math.ceil(filteredData.length / rowsPerPage);
  const startIndex = (page - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const handleOpenFileModal = (fileUrl, fileType, event) => {
    event.preventDefault();
    setSelectedFileUrl(fileUrl);
    setSelectedFileType(fileType);
    setOpenFileModal(true);
  };

  const handleCloseFileModal = () => {
    setOpenFileModal(false);
    setSelectedFileUrl("");
    setSelectedFileType("");
  };

  return (
    <Box sx={{ mx: "auto" }}>
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
        }}
      >
        <TextField
          placeholder={t("header.search_company")}
          variant="standard"
          onChange={handleChange}
          size="small"
          sx={{
            width: "300px",
            "& .MuiInput-underline:before": {
              borderBottom: "1px solid gray",
            },
            "& .MuiInput-underline:after": {
              borderBottom: "2px solid var(--primary-color)",
            },
          }}
          inputRef={searchInputRef}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment
                position="end"
                style={{ display: showClearIcon }}
                onClick={handleClick}
              >
                <ClearIcon style={{ cursor: "pointer" }} />
              </InputAdornment>
            ),
            style: { fontSize: "15px" },
          }}
        />
      </Toolbar>
      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size="medium"
        >
          <TableHead>
            <TableRow>
              {[
                "index",
                "company",
                "email",
                "phone_number",
                "file",
                "register_date",
                "action",
              ].map((header, index) => (
                <TableCell
                  key={header}
                  component="th"
                  scope="row"
                  padding="none"
                  sx={{
                    height: 45,
                    fontWeight: "bold",
                    textAlign: "center",
                    border: "1px solid rgba(224, 224, 224, 1)",
                  }}
                >
                  {t(`university.${header}`)}
                  {(header === "register_date" ||
                    header === "Ngày Đăng Ký") && (
                    <span
                      onClick={handleSort}
                      style={{
                        cursor: "pointer",
                        alignItems: "center",
                      }}
                    >
                      {isAscending ? (
                        <ArrowUpwardIcon sx={{ ml: "5px", mb: "3px" }} />
                      ) : (
                        <ArrowDownwardIcon sx={{ ml: "5px", mb: "3px" }} />
                      )}
                    </span>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.slice(startIndex, endIndex).map((company, index) => (
              <TableRow key={company.id}>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{
                    width: 50,
                    border: "1px solid rgba(224, 224, 224, 1)",
                    textAlign: "center",
                  }}
                >
                  {index + 1}
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{ border: "1px solid rgba(224, 224, 224, 1)" }}
                >
                  {company.fullname}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ border: "1px solid rgba(224, 224, 224, 1)" }}
                >
                  {company.contactEmail}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ border: "1px solid rgba(224, 224, 224, 1)" }}
                >
                  {company.phoneNumber}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ border: "1px solid rgba(224, 224, 224, 1)" }}
                >
                  {internshipFiles[company.id]?.attributes?.internshipFiles
                    ?.data?.length > 0
                    ? internshipFiles[
                        company.id
                      ].attributes.internshipFiles.data.map((file) => (
                        <div key={file.id}>
                          <div className="">
                            <div className="px-3 py-1 rounded-md shadow-sm mb-[5px] flex flex-row justify-between items-center gap-3">
                              <span>{file.attributes.name}</span>
                              <div className="flex flex-row justify-center items-center gap-3">
                                <HiOutlineFolderDownload
                                  className="scale-150 hover:scale-[1.75] cursor-pointer"
                                  onClick={() => {
                                    downloadFile(
                                      file.attributes.file.data.attributes.url,
                                      file.attributes.name
                                    )
                                      .then(() => {
                                        toast.success(
                                          t("notify.download_success")
                                        );
                                      })
                                      .catch(() => {
                                        toast.error(
                                          t("notify.download_failed")
                                        );
                                      });
                                  }}
                                />
                                <IoMdOpen
                                  className="scale-150 hover:scale-[1.75] cursor-pointer"
                                  onClick={(event) =>
                                    handleOpenFileModal(
                                      `${file.attributes.file.data.attributes.url}`,
                                      file.attributes.file.data.attributes.mime,
                                      event
                                    )
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    : ""}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ border: "1px solid rgba(224, 224, 224, 1)" }}
                >
                  {formatDay(company.createdAt)}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ border: "1px solid rgba(224, 224, 224, 1)" }}
                >
                  <div className="flex justify-center space-x-4">
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{ textTransform: "none", backgroundColor: 'var(--primary-color)' }}
                      startIcon={<CheckIcon fontSize="small" />}
                      onClick={() => {
                        confirmAlert({
                          title: "",
                          message: t("notify.are_you_sure_you_want_to_update"),
                          buttons: [
                            {
                              label: t("notify.yes"),
                              onClick: () => {
                                onAccept(company.id);
                              },
                            },
                            {
                              label: t("notify.no"),
                              onClick: () => {},
                            },
                          ],
                        });
                      }}
                    >
                      {t("university.accept")}
                    </Button>
                    <Button
                      variant="contained"
                      color="error"
                      sx={{ textTransform: "none", backgroundColor: "#dc3545" }}
                      startIcon={<CloseIcon fontSize="small" />}
                      onClick={() => {
                        confirmAlert({
                          title: "",
                          message: t("notify.are_you_sure_you_want_to_update"),
                          buttons: [
                            {
                              label: t("notify.yes"),
                              onClick: () => {
                                onReject(company.id);
                              },
                            },
                            {
                              label: t("notify.no"),
                              onClick: () => {},
                            },
                          ],
                        });
                      }}
                    >
                      {t("university.reject")}
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ display: "flex", justifyContent: "flex-end", p: 2 }}>
        <Pagination
          count={totalPages}
          page={page}
          onChange={handleChangePage}
          variant="outlined"
          sx={{
            "& .MuiPaginationItem-root": {
              margin: "0",
              padding: "0",
            },
          }}
          shape="rounded"
        />
      </Box>
      <FileModal
        open={openFileModal}
        handleClose={handleCloseFileModal}
        fileUrl={selectedFileUrl}
        fileType={selectedFileType}
      />
    </Box>
  );
};

export default RegisteredCompaniesTable;

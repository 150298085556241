import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { formatDay } from "../../utils/utils";
import { GiSandsOfTime } from "react-icons/gi";
import { IoMdRemoveCircleOutline } from "react-icons/io";
import { TbPlayerEject } from "react-icons/tb";
import { IoIosCloseCircle } from "react-icons/io";
import { FaCheck } from "react-icons/fa";
import { hideOverlay } from "../../store/common/pageOverlaySlice";
import { useEffect, useState } from "react";

const RegisteredList = () => {
    const { t } = useTranslation();
    const dispartch = useDispatch();
    const currentUser = useSelector((state) => state.userInformationReducer);
    const [isEmpty, setIsEmpty] = useState(false);

    useEffect(() => {
        if (currentUser.registerInternships.length === 0) {
            setIsEmpty(true);
        } else {
            setIsEmpty(false);
        }
    }, [currentUser]);

    return (
        <div className="w-full h-fit p-5">
            <div className="w-full h-fit p-5 flex flex-col bg-white-primary rounded-md shadow-md shadow-white-tertiary mb-7 text-black-secondary relative">
                <div
                    className="absolute right-2 top-2 scale-150 cursor-pointer"
                    onClick={() => dispartch(hideOverlay())}>
                    <IoIosCloseCircle />
                </div>
                <div className="mb-3">
                    <span className="text-lg font-semibold">{"List Registered Internships"}</span>
                </div>
                <div>
                    <table className="min-w-full bg-white border border-white-tertiary">
                        <thead>
                            <tr className="w-full border text-center">
                                <th className="py-3 w-[40px] px-6 font-semibold border">#</th>
                                <th className="py-3 px-6 font-semibold border">
                                    {t("company.name")}
                                </th>
                                <th className="py-3 px-6 font-semibold border">
                                    {"Registered At"}
                                </th>
                                <th className="py-3 px-6 font-semibold border">
                                    {t("company.email")}
                                </th>
                                <th className="py-3 px-6 font-semibold border">
                                    {t("company.phone_number")}
                                </th>
                                <th className="py-3 px-6 font-semibold border">{"Status"}</th>
                            </tr>
                        </thead>
                        {!isEmpty && (
                            <tbody>
                                {currentUser.registerInternships.map((record, index) => (
                                    <tr
                                        key={record.id}
                                        className="hover:bg-white-secondary text-sm text-center">
                                        <td className="py-3 px-4 border">{index + 1}</td>
                                        <td className="py-3 px-4 border">
                                            {record.company.fullname}
                                        </td>
                                        <td className="py-3 px-4 border">
                                            {formatDay(record.createdAt)}
                                        </td>
                                        <td className="py-3 px-4 border">
                                            {record.company.contactEmail
                                                ? record.company.contactEmail
                                                : "[Trống]"}
                                        </td>
                                        <td className="py-3 px-4 border">
                                            {record.company.phoneNumber
                                                ? record.company.phoneNumber
                                                : "[Trống]"}
                                        </td>
                                        <td className="py-3 px-4 border ">
                                            <div className="w-full h-fit flex flex-row justify-center items-center gap-2">
                                                <div
                                                    className={`w-fit h-fit flex flex-row justify-center items-center gap-2 ${
                                                        record.status === "waiting"
                                                            ? "bg-[#ebd109]"
                                                            : record.status === "accepted"
                                                            ? "bg-primary"
                                                            : record.status === "rejected"
                                                            ? "bg-[#dc3545]"
                                                            : "bg-[#707070]"
                                                    } px-4 py-3 rounded-md shadow-md text-white-primary font-bold text-md`}>
                                                    {record.status === "waiting" ? (
                                                        <GiSandsOfTime />
                                                    ) : record.status === "accepted" ? (
                                                        <FaCheck />
                                                    ) : record.status === "rejected" ? (
                                                        <TbPlayerEject />
                                                    ) : (
                                                        <IoMdRemoveCircleOutline />
                                                    )}
                                                    <span>
                                                        {record.status === "waiting"
                                                            ? "Waiting"
                                                            : record.status === "accepted"
                                                            ? "Accepted"
                                                            : record.status === "rejected"
                                                            ? "Rejected"
                                                            : "Removed"}
                                                    </span>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        )}
                    </table>
                    {isEmpty && (
                        <span className="w-full h-fit flex justify-center items-center text-md font-semibold mt-10 mb-5">
                            {t("overview.not_registered")}
                        </span>
                    )}
                </div>
                <div className="w-full h-fit text-end mt-5 ">
                    <span
                        className="w-fit h-fit py-3 px-5 rounded-md shadow-md bg-primary hover:bg-primary-hover cursor-pointer text-white-primary"
                        onClick={() => dispartch(hideOverlay())}>
                        {t("overview.close")}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default RegisteredList;

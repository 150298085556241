import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import RegisteredCompaniesTable from "./registeredCompaniesTable";
import AcceptedCompaniesTable from "./acceptedCompaniesTable";
import { axiosWithJWT } from "../../config/axiosConfig";
import StudentListTable from "./studentListTable";

const Dashboard = () => {
  const { t } = useTranslation();

  const [data, setData] = useState([]);
  const [internshipFiles, setInternshipFiles] = useState({});
  const [filteredRegisteredData, setFilteredRegisteredData] = useState([]);
  const [filteredAcceptedData, setFilteredAcceptedData] = useState([]);

  useEffect(() => {
    setFilteredRegisteredData(data.filter((company) => !company.isAccepted));
  }, [data]);

  useEffect(() => {
    setFilteredAcceptedData(data.filter((company) => company.isAccepted));
  }, [data]);

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const response = await axiosWithJWT.get("/api/companies/?isAdmin=true");
        console.log("response", response);  
        setData(response.data);

        const filesPromises = response.data.data.map((company) =>
          fetchInternshipFiles(company.id)
        );

        const filesResponses = await Promise.all(filesPromises);
        const files = {};

        filesResponses.forEach((filesResponse, index) => {
          const companyId = response.data.data[index].id;
          files[companyId] = filesResponse;
        });

        setInternshipFiles(files);
      } catch (error) {
        console.error("Error fetching company data:", error);
      }
    };

    fetchCompanies();
  }, []);

  const fetchInternshipFiles = async (companyId) => {
    try {
      const response = await axiosWithJWT.get(
        `/api/companies/${companyId}?populate[0]=internshipFiles&populate[1]=internshipFiles.file`
      );
      return response.data.data || [];
    } catch (error) {
      console.error("Error fetching internship files:", error);
      return [];
    }
  };

  const handleAccept = async (companyId) => {
    try {
      await axiosWithJWT.put(`/api/companies/${companyId}`, {
        data: {
          isAccepted: true,
          acceptedIntern: true,
        },
      });

      const updatedData = data.map((company) =>
        company.id === companyId
          ? {
              ...company,
              isAccepted: true,
              acceptedIntern: true,
            }
          : company
      );
      setData(updatedData);
    } catch (error) {
      console.error("Error accepting company:", error);
    }
  };

  const handleReject = async (companyId) => {
    try {
      const response = await axiosWithJWT.delete(`/api/companies/${companyId}`);

      if (response.status === 200) {
        const updatedData = data.filter((company) => company.id !== companyId);
        setData(updatedData);
      }
    } catch (error) {
      console.error("Error rejecting company:", error);
    }
  };

  const handleCompanyUpdate = (updatedCompany) => {
    setData((prevData) => {
      return prevData.map((company) =>
        company.id === updatedCompany.id ? updatedCompany : company
      );
    });
  };

  return (
    <div className="min-w-full w-fit flex-grow bg-black-tertiary p-3 overflow-auto">
      <div className="w-full h-fit p-5 bg-white-primary rounded-md shadow-md shadow-white-tertiary mb-7">
        <div className="mb-3">
          <span className="text-lg font-semibold">
            {t("university.list_of_registered_companies")}
          </span>
        </div>
        <RegisteredCompaniesTable
          data={filteredRegisteredData}
          onAccept={handleAccept}
          onReject={handleReject}
          internshipFiles={internshipFiles}
        />
      </div>

      <div className="min-w-full w-fit h-fit p-5 bg-white-primary rounded-md shadow-md shadow-white-tertiary mb-7">
        <div className="mb-3">
          <span className="text-lg font-semibold">
            {t("university.list_of_accepted_companies")}
          </span>
        </div>
        <AcceptedCompaniesTable
          data={filteredAcceptedData}
          internshipFiles={internshipFiles}
          onCompanyUpdate={handleCompanyUpdate}
        />
      </div>

      <div className="min-w-full w-fit h-fit p-5 bg-white-primary rounded-md shadow-md shadow-white-tertiary mb-7">
        <div className="mb-3">
          <span className="text-lg font-semibold">
            {t("university.list_of_students")}
          </span>
        </div>
        <StudentListTable />
      </div>
    </div>
  );
};

export default Dashboard;

import React, { useEffect, useState } from "react";
import CompanyHeader from "../../components/header/companyHeader";
import MenuItem from "../../components/menu/menuItem";
import { AiFillDashboard } from "react-icons/ai";
import { IoInformationCircle } from "react-icons/io5";
import { IoMdSettings } from "react-icons/io";
import { useTranslation } from "react-i18next";
import Dashboard from "../../components/company/dashboard";
import Information from "../../components/company/information";
import Profile from "../../components/company/profile";
import { useDispatch, useSelector } from "react-redux";
import { hideOverlay, showOverlay } from "../../store/common/pageOverlaySlice";
import { toast } from "react-toastify";
import {
  asyncUserInformation,
  changeUserInformationRedux,
  asyncCompanyInformation,
} from "../../utils/asyncReduxUtils";
import { getUserInformation, updateUserInformation } from "../../api/userApi";
import { createCompany } from "../../api/companyApi";
import Loading from "../../components/loading";

const CompanyPage = () => {
  // LOCAL STATE FOR UI
  const [isLoading, setIsLoading] = useState(true);

  // State for navigate to other component
  const [isDashboard, setIsDashboard] = useState(true);
  const [isCompanyInformation, setIsCompanyInformation] = useState(false);
  const [isProfileSetting, setIsProfileSetting] = useState(false);

  // Get translation
  const { t } = useTranslation();

  // State show overlay from Redux
  const isShowOverlay = useSelector(
    (state) => state.pageOverlayReducer.showOverlay
  );

  // Get company information from Redux
  const companyInformation = useSelector(
    (state) => state.userInformationReducer.company
  );

  // INTERACT WITH API

  // Interact with Redux
  const dispatch = useDispatch();

  useEffect(() => {
    // Get user information when page is loaded
    getUserInformation()
      .then((user) => {
        // If user has no company
        if (!user.company) {
          // Create new company with plant data
          createCompany({
            data: {
              publishedAt: new Date().toISOString(),
            },
          })
            .then((company) => {
              // Update user information with the comapny id
              updateUserInformation({ company: company.id }, user.id)
                .then(() => {
                  // Get user information again
                  getUserInformation()
                    .then((userUpdated) => {
                      asyncCompanyInformation(dispatch, userUpdated.company.id);
                      changeUserInformationRedux(dispatch, userUpdated);
                    })
                    .catch((error) => {
                      toast.error(error.response.data.error.message);
                    });
                })
                .catch((error) => {
                  // Error if cannot update user information
                  toast.error(error.response.data.error.message);
                });
            })
            .catch((error) => {
              // Error if cannot create new company
              toast.error(error.response.data.error.message);
            });

          // Set isNewUser to true => show dialog asking for update comapny information
          toast.success(t("notify.welcome_new_user"));
        } else {
          // Set user information to Redux if user has company
          changeUserInformationRedux(dispatch, user);
          asyncCompanyInformation(dispatch, user.company.id);
        }
      })
      .catch((error) => {
        // Error if cannot get user information
        toast.error(error.response.data.error.message);
      })
      .finally(() => {
        // Set loading to false
        setIsLoading(false);
      });
  }, []);

  if (isLoading || !companyInformation) {
    return (
      <div className="w-full h-screen flex justify-center items-center">
        <Loading />
      </div>
    );
  }

  return (
    <div className="w-full h-screen flex flex-col justify-center items-center relative">
      {/* Company Header */}
      <div className="sticky top-0 w-full z-30">
        <CompanyHeader
          listMenuItems={[
            <div
              className={`w-full my-1 ${
                isDashboard
                  ? " bg-primary-hover shadow-md"
                  : "border-l-transparent"
              }`}
              onClick={() => {
                setIsDashboard(true);
                setIsCompanyInformation(false);
                setIsProfileSetting(false);
                dispatch(hideOverlay());
              }}
            >
              <div className="flex flex-row justify-start items-center gap-4 w-full h-fit px-6 py-[10px] hover:bg-primary-hover hover:shadow-md rounded-[4px] cursor-pointer">
                <AiFillDashboard />
                <span className={`text-[15px] flex-grow`}>
                  {t("company.dashboard")}
                </span>
              </div>
            </div>,
            <div
              className={`w-full my-1 ${
                isCompanyInformation
                  ? "bg-primary-hover shadow-md"
                  : "border-l-transparent"
              }`}
              onClick={() => {
                setIsCompanyInformation(true);
                setIsDashboard(false);
                setIsProfileSetting(false);
                dispatch(hideOverlay());
              }}
            >
              <div className="flex flex-row justify-start items-center gap-4 w-full h-fit px-6 py-[10px] hover:bg-primary-hover hover:shadow-md rounded-[4px] cursor-pointer">
                <IoInformationCircle />
                <span className={`text-[15px] flex-grow`}>
                  {t("company.company_information")}
                </span>
              </div>
            </div>,
            <div
              className={`w-full my-1 ${
                isProfileSetting
                  ? " bg-primary-hover shadow-md"
                  : "border-l-transparent"
              }`}
              onClick={() => {
                setIsProfileSetting(true);
                setIsDashboard(false);
                setIsCompanyInformation(false);
                dispatch(hideOverlay());
              }}
            >
              <div className="flex flex-row justify-start items-center gap-4 w-full h-fit px-6 py-[10px] hover:bg-primary-hover hover:shadow-md rounded-[4px] cursor-pointer">
                <IoMdSettings />
                <span className={`text-[15px] flex-grow`}>
                  {t("company.profile")}
                </span>
              </div>
            </div>,
          ]}
        />
      </div>

      <div
        className={`w-full h-full bg-transparent absolute top-0 z-20 ${
          isShowOverlay ? "" : "hidden"
        }`}
        onClick={() => dispatch(hideOverlay())}
      />

      {/* Company body */}
      <div className="w-full h-fit flex-grow flex flex-row justify-center items-start overflow-auto">
        {/* Navigate */}
        <div className="min-w-[300px] hidden lg:block xl:relative text-navigate-text h-full xl:p-3 p-1 shadow-md shadow-white-tertiary z-20 bg-navigate-background border-t-[1px] border-navigate-background-hover">
          <div></div>
          <div className="w-full h-fit flex flex-row justify-start items-center gap-3 px-2 py-3 text-md mb-3">
            <img
              src={
                companyInformation.image.thumbnail !== ""
                  ? companyInformation.image.thumbnail
                  : companyInformation.image.original !== ""
                  ? companyInformation.image.original
                  : 'no_image.png'
              }
              alt=""
              className="w-[50px] h-50px]"
            />
            <span>{companyInformation.fullname}</span>
          </div>
          <div className="w-full h-fit flex-grow flex flex-col justify-start items-center gap-2 ">
            <div
              className={`w-full border-l-[5px] rounded-e-md ${
                isDashboard
                  ? "border-l-primary bg-navigate-background-hover shadow-md"
                  : "border-l-transparent"
              }`}
              onClick={() => {
                setIsDashboard(true);
                setIsCompanyInformation(false);
                setIsProfileSetting(false);
              }}
            >
              <MenuItem
                backgroundColorHover={"bg-navigate-background-hover"}
                menuIcon={
                  <div className="scale-125">
                    <AiFillDashboard />{" "}
                  </div>
                }
                menuContext={t("company.dashboard")}
              />
            </div>
            <div
              className={`w-full border-l-[5px] rounded-e-md ${
                isCompanyInformation
                  ? "border-l-primary bg-navigate-background-hover shadow-md"
                  : "border-l-transparent"
              }`}
              onClick={() => {
                setIsCompanyInformation(true);
                setIsDashboard(false);
                setIsProfileSetting(false);
              }}
            >
              <MenuItem
                backgroundColorHover={"bg-navigate-background-hover"}
                menuIcon={
                  <div className="scale-125">
                    <IoInformationCircle />
                  </div>
                }
                menuContext={t("company.company_information")}
              />
            </div>
            <div
              className={`w-full border-l-[5px] rounded-e-md ${
                isProfileSetting
                  ? "border-l-primary bg-navigate-background-hover shadow-md"
                  : "border-l-transparent"
              }`}
              onClick={() => {
                setIsProfileSetting(true);
                setIsDashboard(false);
                setIsCompanyInformation(false);
              }}
            >
              <MenuItem
                backgroundColorHover={"bg-navigate-background-hover"}
                menuIcon={
                  <div className="scale-125">
                    <IoMdSettings />
                  </div>
                }
                menuContext={t("company.profile")}
              />
            </div>
          </div>
        </div>

        {/* Content */}
        <div className="w-full h-full flex-grow bg-white-primary flex flex-col justify-start items-start overflow-auto">
          <div className="w-full min-h-[90px] lg:shadow-md shadow-white-secondary shadow-none bg-white-primary z-10">
            <div className="w-fit h-full flex flex-col justify-center items-start px-7 gap-[3px]">
              <span className="text-lg font-semibold">{`${companyInformation.fullname} (${companyInformation.shortname})`}</span>

              {isDashboard && (
                <div className="w-fit h-fit flex flex-row justify-end items-center gap-2">
                  <div className="scale-125">
                    <AiFillDashboard />
                  </div>
                  <span>{t("company.dashboard")}</span>
                </div>
              )}
              {isCompanyInformation && (
                <div className="w-fit h-fit flex flex-row justify-end items-center gap-2">
                  <div className="scale-125">
                    <IoInformationCircle />
                  </div>
                  <span>{t("company.company_information")}</span>
                </div>
              )}
              {isProfileSetting && (
                <div className="w-fit h-fit flex flex-row justify-end items-center gap-2">
                  <div className="scale-125">
                    <IoMdSettings />
                  </div>
                  <span>{t("company.profile")}</span>
                </div>
              )}
            </div>
          </div>

          {isDashboard && <Dashboard />}

          {isCompanyInformation && <Information />}

          {isProfileSetting && <Profile />}
        </div>
      </div>
    </div>
  );
};

export default CompanyPage;

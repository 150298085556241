import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import AppRoutes from './routes/routes';
import { useDispatch } from 'react-redux';
import { asyncThemeInformation } from './utils/asyncReduxUtils';
import { useEffect } from 'react';
import { getBrand } from './api/brandApi';
import { darkenRgbColor } from './utils/utils';
import convert from 'color-convert';

function App() {
  const dispatch = useDispatch();

  const applyThemeToRoot = (themeData) => {
    if (!themeData?.css) return;

    const { primaryColorRGB, primaryColor, headerTextColor, loginTextColor, loginBackgroundColor, loginBackgroundColorRGB, navigateTextColor, navigateBackgroundColor, navigateBackgroundColorRGB } = themeData.css;

    const darkPrimaryColor = '#' + convert.rgb.hex(darkenRgbColor(primaryColorRGB, 28));
    const darkNavigateColor = '#' + convert.rgb.hex(darkenRgbColor(navigateBackgroundColorRGB, 28));
    const darkLoginColor = '#' + convert.rgb.hex(darkenRgbColor(loginBackgroundColorRGB, 28));

    document.documentElement.style.setProperty('--primary-color', primaryColor);
    document.documentElement.style.setProperty('--primary-hover-color', darkPrimaryColor);
    document.documentElement.style.setProperty('--header-text-color', headerTextColor);
    document.documentElement.style.setProperty('--login-text-color', loginTextColor);
    document.documentElement.style.setProperty('--login-background-color', loginBackgroundColor);
    document.documentElement.style.setProperty('--login-background-hover-color', darkLoginColor);
    document.documentElement.style.setProperty('--navigate-text-color', navigateTextColor);
    document.documentElement.style.setProperty('--navigate-background-color', navigateBackgroundColor);
    document.documentElement.style.setProperty('--navigate-background-hover-color', darkNavigateColor);
  };

  const setFavicon = (url) => {
    const link = document.querySelector("link[rel='icon']") || document.createElement('link');
    link.type = 'image/png';
    link.rel = 'icon';
    link.href = url;
    document.head.appendChild(link);
  };

  const setTitle = (title) => {
    document.title = "Intern " + title;
  }

  const initializeTheme = async () => {
    try {
      const brandData = await getBrand();
      applyThemeToRoot(brandData);
      setTitle(brandData.websiteName);
      setFavicon(brandData.logo.url);
      asyncThemeInformation(dispatch, brandData);
    } catch (error) {
      console.error('Error fetching theme data:', error);
    }
  };

  useEffect(() => {
    initializeTheme();
  }, [dispatch]);

  return (
    <div>
      <ToastContainer />
      <AppRoutes />
    </div>
  );
}

export default App;

import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Modal,
  Box,
  Button,
  Switch,
  TextField,
  Tooltip,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { toast } from "react-toastify";

const EditModal = ({ open, handleClose, companyData, handleSave }) => {
  const { t } = useTranslation();
  const [maxRegister, setMaxRegister] = useState(0);
  const [maxAcceptedStudent, setMaxAcceptedStudent] = useState(0);
  const [isAccepted, setIsAccepted] = useState(true);
  const [acceptedIntern, setAcceptedIntern] = useState(true);
  const [active, setActive] = useState(true);
  const [isSponsor, setIsSponsor] = useState(false);
  const [ratio, setRatio] = useState("1x1");
  const [size, setSize] = useState("s");

  const handleChangeWidth = (event) => {
    setRatio(event.target.value);
  };

  const SwitchCustom = styled(Switch)(({ theme }) => ({
    padding: 8,
    "& .MuiSwitch-track": {
      borderRadius: 22 / 2,
      backgroundColor: "#aeaeae",
      opacity: 1,
      "&::before, &::after": {
        content: '""',
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        width: 16,
        height: 16,
      },
      "&::before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText("#0a426e")
        )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
        left: 12,
      },
      "&::after": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText("#0a426e")
        )}" d="M19,13H5V11H19V13Z" /></svg>')`,
        right: 12,
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "none",
      width: 16,
      height: 16,
      margin: 2,
      backgroundColor: "#fefefe",
    },
    "& .Mui-checked": {
      "& .MuiSwitch-thumb": {
        backgroundColor: "#0a426e",
      },
    },
  }));

  useEffect(() => {
    if (companyData) {
      setMaxRegister(companyData.maxRegister);
      setMaxAcceptedStudent(companyData.maxAcceptedStudent);
      setIsAccepted(companyData.isAccepted);
      setAcceptedIntern(companyData.acceptedIntern);
      setActive(companyData.active);
      setIsSponsor(companyData.isSponsor);
      setRatio(calculateRatio(companyData.width, companyData.height));
      setSize(calculateSize(companyData.width, companyData.height));
    }
  }, [companyData]);

  const handleAdminMaxRegisterChange = (e) => {
    const value = Math.max(0, parseInt(e.target.value) || 0);
    setMaxRegister(value);
  };

  const handleMaxAcceptedStudentsChange = (e) => {
    const value = Math.max(0, parseInt(e.target.value) || 0);
    setMaxAcceptedStudent(value);
  };

  const calculateWidthHeight = () => {
    let width = 1;
    let height = 1;
    switch (ratio) {
      case "1x1":
        width = 1;
        height = 1;
        break;
      case "1x2":
        width = 1;
        height = 2;
        break;
      case "2x1":
        width = 2;
        height = 1;
        break;
      default:
        break;
    }
    switch (size) {
      case "s":
        width *= 1;
        height *= 1;
        break;
      case "m":
        width *= 2;
        height *= 2;
        break;
      case "l":
        width *= 3;
        height *= 3;
        break;
      case "xl":
        width *= 4;
        height *= 4;
        break;
      default:
        break;
    }
    return { width, height };
  };

  const calculateRatio = (width, height) => {
    if (width === height) {
      return "1x1";
    } else if (width === height / 2) {
      return "1x2";
    } else if (height === width / 2) {
      return "2x1";
    } else {
      return "1x1";
    }
  };

  const calculateSize = (width, height) => {
    const sizeArray = ["s", "m", "l", "xl"];
    const ratio = calculateRatio(width, height);
    if (ratio === "2x1") {
      return sizeArray[height - 1];
    }
    return sizeArray[width - 1];
  };

  const handleSubmit = async () => {
    // Calculate size of logo based on ratio and size
    const { width, height } = calculateWidthHeight();

    // Update data
    const updatedData = {
      id: companyData.id,
      maxRegister,
      maxAcceptedStudent,
      acceptedIntern,
      active,
      isAccepted,
      isSponsor,
      width,
      height,
    };

    await handleSave(updatedData);
    toast.success(t("notify.update_success"));
    handleClose();
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box className="flex flex-col px-7 pt-7 pb-5 w-[370px] bg-white rounded-md shadow-lg mx-auto mt-20">
        <h2 className="text-xl font-bold mb-5 text-center">
          {t("university.edit_company")}
        </h2>

        <div className="flex flex-col justify-center gap-3">
          <TextField
            label={t("university.max_register")}
            variant="outlined"
            type="number"
            placeholder="0"
            value={maxRegister}
            onChange={handleAdminMaxRegisterChange}
            onFocus={() => {
              if (maxRegister === 0) {
                setMaxRegister("");
              }
            }}
            onBlur={() => {
              if (maxRegister === "") {
                setMaxRegister(0);
              }
            }}
            inputProps={{ min: 0 }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "&:hover fieldset": {
                  borderColor: "var(--primary-color)",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "var(--primary-color)",
                  borderWidth: "1px",
                },
              },
              "& .MuiInputLabel-root": {
                color: "gray",
              },
              "& .MuiInputLabel-root.Mui-focused": {
                color: "#0a426e",
              },
            }}
          />

          <TextField
            label={t("university.max_accepted_student")}
            variant="outlined"
            type="number"
            placeholder="0"
            value={maxAcceptedStudent}
            onChange={handleMaxAcceptedStudentsChange}
            onFocus={() => {
              if (maxAcceptedStudent === 0) {
                setMaxAcceptedStudent("");
              }
            }}
            onBlur={() => {
              if (maxAcceptedStudent === "") {
                setMaxAcceptedStudent(0);
              }
            }}
            inputProps={{ min: 0 }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "&:hover fieldset": {
                  borderColor: "var(--primary-color)",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "var(--primary-color)",
                  borderWidth: "1px",
                },
              },
              "& .MuiInputLabel-root": {
                color: "gray",
              },
              "& .MuiInputLabel-root.Mui-focused": {
                color: "#0a426e",
              },
            }}
          />

          <FormControl sx={{ minWidth: 120 }}>
            <InputLabel id="logo-ratio">{t("university.ratio")}</InputLabel>
            <Select
              labelId="logo-ratio"
              value={ratio}
              label={t("university.ratio")}
              onChange={(e) => setRatio(e.target.value)}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "&:hover fieldset": {
                    borderColor: "var(--primary-color)",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "var(--primary-color)",
                    borderWidth: "1px",
                  },
                },
                "& .MuiInputLabel-root": {
                  color: "gray",
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: "var(--primary-color)",
                },
              }}
            >
              <MenuItem value="1x1">1 x 1 (Hình Vuông)</MenuItem>
              <MenuItem value={"1x2"}>1 x 2 (Hình Chữ Nhật Đứng)</MenuItem>
              <MenuItem value={"2x1"}>2 x 1 (Hình Chữ Nhật Nằm)</MenuItem>
            </Select>
          </FormControl>

          <FormControl sx={{ minWidth: 120 }}>
            <InputLabel id="logo-size">{t("university.size")}</InputLabel>
            <Select
              labelId="logo-size"
              value={size}
              label={t("university.size")}
              onChange={(e) => setSize(e.target.value)}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "&:hover fieldset": {
                    borderColor: "var(--primary-color)",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "var(--primary-color)",
                    borderWidth: "1px",
                  },
                },
                "& .MuiInputLabel-root": {
                  color: "gray",
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: "var(--primary-color)",
                },
              }}
            >
              <MenuItem value="s">S (x1)</MenuItem>
              <MenuItem value={"m"}>M (x2)</MenuItem>
              <MenuItem value={"l"}>L (x3)</MenuItem>
              <MenuItem value={"xl"}>XL (x4)</MenuItem>
            </Select>
          </FormControl>
        </div>

        <div className="flex flex-col w-full h-fit justify-center items-start pl-2">
          <div className="w-full h-fit flex flex-row justify-between items-center">
            <div className="flex-grow flex flex-row justify-between items-center">
              <span>{t("university.accepted")}</span>
              <SwitchCustom
                checked={isAccepted && acceptedIntern}
                onChange={(e) => {
                  const checked = e.target.checked;
                  setIsAccepted(checked);
                  setAcceptedIntern(checked);
                }}
              />
            </div>
            <Tooltip title={t("university.accept_company_by_university")} arrow>
              <IconButton size="small">
                <HelpOutlineIcon sx={{ color: "gray", fontSize: "15px" }} />
              </IconButton>
            </Tooltip>
          </div>
          <div className="w-full h-fit flex flex-row justify-between items-center">
            <div className="flex-grow flex flex-row justify-between items-center">
              <span>{t("university.sponsor")}</span>
              <SwitchCustom
                checked={isSponsor}
                onChange={(e) => {
                  const checked = e.target.checked;
                  setIsSponsor(checked);
                }}
              />
            </div>
            <Tooltip title={t("university.sponsor_tooltip")} arrow>
              <IconButton size="small">
                <HelpOutlineIcon sx={{ color: "gray", fontSize: "15px" }} />
              </IconButton>
            </Tooltip>
          </div>

          <div className="w-full h-fit flex flex-row justify-between items-center">
            <div className="flex-grow flex flex-row justify-between items-center">
              <span>{t("university.active")}</span>
              <SwitchCustom
                checked={active}
                onChange={(e) => setActive(e.target.checked)}
                color="primary"
              />
            </div>

            <Tooltip title={t("university.still_active")} arrow>
              <IconButton size="small">
                <HelpOutlineIcon sx={{ color: "gray", fontSize: "15px" }} />
              </IconButton>
            </Tooltip>
          </div>
        </div>

        <div className="flex justify-end mt-5 mb-1">
          <Button
            variant="outlined"
            onClick={handleClose}
            sx={{ mr: "5px", borderColor: "var(--primary-color)", color: "var(--primary-color)" }}
          >
            {t("university.cancel")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            sx={{
              borderColor: "var(--primary-color)",
              color: "#ffffff",
              backgroundColor: "var(--primary-color)",
            }}
          >
            {t("university.save")}
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default EditModal;

import React, { useState, useEffect } from "react";
import UniversityHeader from "../../components/header/universityHeader";
import MenuItem from "../../components/menu/menuItem";
import { AiFillDashboard } from "react-icons/ai";
import { IoInformationCircle } from "react-icons/io5";
import { IoMdSettings } from "react-icons/io";
import { useTranslation } from "react-i18next";
import { BiSolidCustomize } from "react-icons/bi";
import Dashboard from "../../components/university/dashboard";
import Information from "../../components/university/information";
import Profile from "../../components/university/profile";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  asyncCompanyInformation,
  asyncUserInformation,
  changeUserInformationRedux,
} from "../../utils/asyncReduxUtils";
import Loading from "../../components/loading";
import { createCompany } from "../../api/companyApi";
import { getUserInformation, updateUserInformation } from "../../api/userApi";
import { hideOverlay } from "../../store/common/pageOverlaySlice";
import Custom from "../../components/university/custom";

const UniversityPage = () => {
  const dispatch = useDispatch();
  const [isDashboard, setIsDashboard] = useState(true);
  const [isCustom, setIsCustom] = useState(false);
  const [isUniversityInformation, setIsUniversityInformation] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isProfileSetting, setIsProfileSetting] = useState(false);
  const isShowOverlay = useSelector(
    (state) => state.pageOverlayReducer.showOverlay
  );

  const { t } = useTranslation();

  useEffect(() => {
    // Get user information when page is loaded
    getUserInformation()
      .then((user) => {
        // If user has no company
        if (!user.company) {
          // Create new company with plant data
          createCompany({
            data: {
              publishedAt: new Date().toISOString(),
            },
          })
            .then((company) => {
              // Update user information with the comapny id
              updateUserInformation({ company: company.id }, user.id)
                .then(() => {
                  // Get user information again
                  asyncUserInformation(dispatch).catch((error) => {
                    // Error if cannot get user information agai
                    toast.error(error.response.data.error.message);
                  });
                  asyncCompanyInformation(dispatch, user.company.id);
                })
                .catch((error) => {
                  // Error if cannot update user information
                  toast.error(error.response.data.error.message);
                });
            })
            .catch((error) => {
              // Error if cannot create new company
              toast.error(error.response.data.error.message);
            });

          // Set isNewUser to true => show dialog asking for update comapny information
          toast.success(t("notify.welcome_new_user"));
        } else {
          // Set user information to Redux if user has company
          changeUserInformationRedux(dispatch, user);
          asyncCompanyInformation(dispatch, user.company.id);
        }
      })
      .catch((error) => {
        // Error if cannot get user information
        toast.error(error.response.data.error.message);
      })
      .finally(() => {
        // Set loading to false
        setIsLoading(false);
      });
  }, []);

  const universityInformation = useSelector(
    (state) => state.userInformationReducer.company
  );

  if (isLoading || !universityInformation) {
    return (
      <div className="w-full h-screen flex justify-center items-center">
        <Loading />
      </div>
    );
  }

  return (
    <div className="w-full h-screen flex flex-col justify-center items-center relative">
      <div
        className={`w-full h-full bg-transparent absolute top-0 z-20 ${
          isShowOverlay ? "" : "hidden"
        }`}
        onClick={() => dispatch(hideOverlay())}
      />
      {/* University Header */}
      <div className="sticky top-0 w-full z-30">
        <UniversityHeader
          listMenuItems={[
            <div
              className={`w-full my-1 ${
                isDashboard
                  ? " bg-primary-hover shadow-md"
                  : "border-l-transparent"
              }`}
              onClick={() => {
                setIsUniversityInformation(false);
                setIsDashboard(true);
                setIsProfileSetting(false);
                setIsCustom(false);
                dispatch(hideOverlay());
              }}
            >
              <div className="flex flex-row justify-start items-center gap-4 w-full h-fit px-6 py-[10px] hover:bg-primary-hover hover:shadow-md rounded-[4px] cursor-pointer">
                <AiFillDashboard />
                <span className={`text-[15px] flex-grow whitespace-nowrap`}>
                  {t("university.dashboard")}
                </span>
              </div>
            </div>,
            <div
              className={`w-full my-1 ${
                isCustom
                  ? " bg-primary-hover shadow-md"
                  : "border-l-transparent"
              }`}
              onClick={() => {
                setIsUniversityInformation(false);
                setIsDashboard(false);
                setIsCustom(true);
                setIsProfileSetting(false);
                dispatch(hideOverlay());
              }}
            >
              <div className="flex flex-row justify-start items-center gap-4 w-full h-fit px-6 py-[10px] hover:bg-primary-hover hover:shadow-md rounded-[4px] cursor-pointer">
                <BiSolidCustomize />
                <span className={`text-[15px] flex-grow whitespace-nowrap`}>
                  {t("university.custom")}
                </span>
              </div>
            </div>,
            <div
              className={`w-full my-1 ${
                isUniversityInformation
                  ? "bg-primary-hover shadow-md"
                  : "border-l-transparent"
              }`}
              onClick={() => {
                setIsUniversityInformation(true);
                setIsProfileSetting(false);
                setIsDashboard(false);
                setIsCustom(false);
                dispatch(hideOverlay());
              }}
            >
              <div className="flex flex-row justify-start items-center gap-4 w-full h-fit px-6 py-[10px] hover:bg-primary-hover hover:shadow-md rounded-[4px] cursor-pointer">
                <IoInformationCircle />
                <span className="text-[15px] flex-grow whitespace-nowrap">
                  {t("university.university_information")}
                </span>
              </div>
            </div>,
            <div
              className={`w-full ${
                isProfileSetting
                  ? " bg-primary-hover shadow-md"
                  : "border-l-transparent"
              }`}
              onClick={() => {
                setIsDashboard(false);
                setIsUniversityInformation(false);
                setIsProfileSetting(true);
                setIsCustom(false);
                dispatch(hideOverlay());
              }}
            >
              <div className="flex flex-row justify-start items-center gap-4 w-full h-fit px-6 py-[10px] hover:bg-primary-hover hover:shadow-md rounded-[4px] cursor-pointer">
                <IoMdSettings />
                <span className={`text-[15px] flex-grow whitespace-nowrap`}>
                  {t("university.profile")}
                </span>
              </div>
            </div>,
          ]}
        />
      </div>

      <div
        className={`w-full h-full bg-transparent absolute top-0 z-20 ${
          isShowOverlay ? "" : "hidden"
        }`}
        onClick={() => dispatch(hideOverlay())}
      />

      <div className="w-full h-fit flex-grow flex flex-row justify-center items-start overflow-auto">
        {/* Navigate */}
        <div className="min-w-[300px] lg:block hidden xl:relative bg-navigate-background h-full xl:p-3 p-1 shadow-md shadow-white-tertiary z-20 border-t-[1px] text-navigate-text border-navigate-background-hover">
          <div className="w-full h-fit flex flex-row justify-start items-center gap-3 px-2 py-3 text-md mb-3">
            <img
              src={
                universityInformation.image.thumbnail !== ""
                  ? universityInformation.image.thumbnail
                  : universityInformation.image.original !== ""
                  ? universityInformation.image.original
                  : 'no_image.png'
              }
              alt=""
              className="w-[50px] h-50px]"
            />
            <span>{`${universityInformation.fullname}`}</span>
          </div>
          <div className="w-full h-fit flex-grow flex flex-col justify-start items-center gap-2">
            <div
              className={`w-full border-l-[5px] rounded-e-md ${
                isDashboard
                  ? "border-l-primary bg-navigate-background-hover shadow-md"
                  : "border-l-transparent"
              }`}
              onClick={() => {
                setIsDashboard(true);
                setIsCustom(false);
                setIsUniversityInformation(false);
                setIsProfileSetting(false);
              }}
            >
              <MenuItem
                backgroundColorHover={"bg-navigate-background-hover"}
                menuIcon={
                  <div className="scale-125">
                    <AiFillDashboard />{" "}
                  </div>
                }
                menuContext={t("university.dashboard")}
              />
            </div>
            <div
              className={`w-full border-l-[5px] rounded-e-md ${
                isCustom
                  ? "border-l-primary bg-navigate-background-hover shadow-md"
                  : "border-l-transparent"
              }`}
              onClick={() => {
                setIsUniversityInformation(false);
                setIsDashboard(false);
                setIsCustom(true);
                setIsProfileSetting(false);
              }}
            >
              <MenuItem
                backgroundColorHover={"bg-navigate-background-hover"}
                menuIcon={
                  <div className="scale-125">
                    <BiSolidCustomize />
                  </div>
                }
                menuContext={t("university.custom")}
              />
            </div>
            <div
              className={`w-full border-l-[5px] rounded-e-md ${
                isUniversityInformation
                  ? "border-l-primary bg-navigate-background-hover shadow-md"
                  : "border-l-transparent"
              }`}
              onClick={() => {
                setIsUniversityInformation(true);
                setIsDashboard(false);
                setIsCustom(false);
                setIsProfileSetting(false);
              }}
            >
              <MenuItem
                backgroundColorHover={"bg-navigate-background-hover"}
                menuIcon={
                  <div className="scale-125">
                    <IoInformationCircle />
                  </div>
                }
                menuContext={t("university.university_information")}
              />
            </div>
            <div
              className={`w-full border-l-[5px] rounded-e-md ${
                isProfileSetting
                  ? "border-l-primary bg-navigate-background-hover shadow-md"
                  : "border-l-transparent"
              }`}
              onClick={() => {
                setIsProfileSetting(true);
                setIsDashboard(false);
                setIsCustom(false);
                setIsUniversityInformation(false);
              }}
            >
              <MenuItem
                backgroundColorHover={"bg-navigate-background-hover"}
                menuIcon={
                  <div className="scale-125">
                    <IoMdSettings />
                  </div>
                }
                menuContext={t("university.profile")}
              />
            </div>
          </div>
        </div>

        {/* Content */}
        <div className="w-full h-full flex-grow bg-white-primary flex flex-col justify-start items-start overflow-auto">
          <div className="w-full min-h-[90px] lg:shadow-md shadow-white-secondary shadow-none bg-white-primary z-10">
            <div className="w-fit h-full flex flex-col justify-center items-start px-7 gap-[3px]">
              <span className="text-lg font-semibold">
                {`${universityInformation.fullname} (${universityInformation.shortname})`}
              </span>
              {isDashboard && (
                <div className="w-fit h-fit flex flex-row justify-end items-center gap-2">
                  <div className="scale-125">
                    <AiFillDashboard />
                  </div>
                  <span>{t("university.dashboard")}</span>
                </div>
              )}
              {isCustom && (
                <div className="w-fit h-fit flex flex-row justify-end items-center gap-2">
                  <div className="scale-125">
                    <BiSolidCustomize />
                  </div>
                  <span>{t("university.custom")}</span>
                </div>
              )}
              {isUniversityInformation && (
                <div className="w-fit h-fit flex flex-row justify-end items-center gap-2">
                  <div className="scale-125">
                    <IoInformationCircle />
                  </div>
                  <span>{t("university.university_information")}</span>
                </div>
              )}
              {isProfileSetting && (
                <div className="w-fit h-fit flex flex-row justify-end items-center gap-2">
                  <div className="scale-125">
                    <IoMdSettings />
                  </div>
                  <span>{t("university.profile")}</span>
                </div>
              )}
            </div>
          </div>

          {isDashboard && <Dashboard />}

          {isCustom && <Custom />}

          {isUniversityInformation && <Information />}

          {isProfileSetting && <Profile />}
        </div>
      </div>
    </div>
  );
};

export default UniversityPage;

import { axiosWithJWT } from "../config/axiosConfig";

const acceptStudent = async (studentId) => {
  try {
    const response = await axiosWithJWT.put(
      `/api/register-internship/accept/${studentId}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const rejectStudent = async (studentId) => {
  try {
    const response = await axiosWithJWT.put(
      `/api/register-internship/reject/${studentId}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export { acceptStudent, rejectStudent };

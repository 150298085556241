import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { BsFiletypeCsv } from "react-icons/bs";

const CsvDropzone = ({ onCsvUpload }) => {
  const { t } = useTranslation();
  const [fileName, setFileName] = useState("");

  const onDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      setFileName(file.name);

      if (onCsvUpload) {
        onCsvUpload(file);
      }
    },
    [onCsvUpload]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: ".csv",
    multiple: false,
  });

  return (
    <div className="w-full flex flex-col items-center" {...getRootProps()}>
      <input {...getInputProps()} />

      <div className="w-full h-[150px] flex justify-center items-center p-2 border-dashed border-[1px] border-black-secondary cursor-pointer">
        {fileName ? (
          <div className="flex flex-row w-fit h-fit justify-center items-center gap-3 bg-blue-primary text-white-primary px-5 py-1 rounded-md shadow-md">
            <BsFiletypeCsv className="scale-125"/>
            <span className="text-md">{fileName}</span>
          </div>
        ) : (
          <span className="text-md text-gray-500">
            {t("notify.drop_csv_file_here")}
          </span>
        )}
      </div>
      {isDragActive ? (
        <span className="text-[12px] text-blue-primary">
          {t("notify.drag_csv_file_here")}
        </span>
      ) : (
        <span className="text-[12px] text-blue-primary">
          {t("notify.click_or_drag_csv_file_to_the_above_box")}
        </span>
      )}
    </div>
  );
};

export default CsvDropzone;

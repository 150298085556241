import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import FileUpload from "../upload/image";
import { useDispatch, useSelector } from "react-redux";
import { updateUserInformation } from "../../api/userApi";
import { toast } from "react-toastify";
import { uploadFile } from "../../api/uploadFile";
import { asyncUserInformation } from "../../utils/asyncReduxUtils";
import { axiosWithJWT } from "../../config/axiosConfig";
import { isEmpty } from "../../utils/utils";
import { confirmAlert } from "../react-confirm-alert/lib";

const Profile = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const currentUser = useSelector((state) => state.userInformationReducer);

    const [name, setName] = useState(currentUser.name);
    const [phoneNumber, setPhoneNumber] = useState(currentUser.phoneNumber);
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [isChangePassword, setIsChangePassword] = useState(false);
    const [newUploadImage, setNewUploadImage] = useState(null);

    const handleOnClick = () => {
        // Check if name and phone number are empty
        if (isEmpty(name) || isEmpty(phoneNumber)) {
            toast.error(t("notify.empty_fields"));
            return;
        }

        // Check if phone number is invalid
        if (phoneNumber.length < 10 || phoneNumber.length > 11) {
            toast.error(t("notify.invalid_phone_number"));
            return;
        }

        // Check if change password is enabled
        if (isChangePassword) {
            if (isEmpty(currentPassword) || isEmpty(newPassword) || isEmpty(confirmPassword)) {
                toast.error(t("notify.empty_fields"));
                return;
            }
            if (newPassword !== confirmPassword) {
                toast.error(t("notify.password_not_match"));
                return;
            }
        }

        confirmAlert({
            title: "",
            message: t("notify.are_you_sure_you_want_to_update"),
            buttons: [
                {
                    label: t("notify.yes"),
                    onClick: () => {
                        handleUpdateProfile();
                    },
                },
                {
                    label: t("notify.no"),
                    onClick: () => {},
                },
            ],
        });
    };

    const handleUpdateProfile = async () => {
        try {
            if (newUploadImage) {
                await handleImageUpload(newUploadImage);
            }

            await updateUserInformation(
                {
                    name: name,
                    phoneNumber: phoneNumber,
                },
                currentUser.id
            );

            if (isChangePassword) {
                await handleChangePassword();
            }

            asyncUserInformation(dispatch);
            toast.success(t("notify.update_success"));
        } catch (error) {
            toast.error(t("notify.update_failed " + error.response.data.error.message));
        }
    };

    const handleImageUpload = async (file) => {
        try {
            await uploadFile("plugin::users-permissions.user", currentUser.id, "image", file);
        } catch (error) {
            toast.error(error.response.data.error.message);
        }
    };

    const handleChangePassword = async () => {
        try {
            await axiosWithJWT.post("/api/auth/change-password", {
                currentPassword: currentPassword,
                password: newPassword,
                passwordConfirmation: confirmPassword,
            });
        } catch (error) {
            toast.error(error.response.data.error.message);
        }
    };

    const handleFileChange = (file) => {
        setNewUploadImage(file);
    };

    return (
        <div className="w-full flex-grow bg-black-tertiary p-3 overflow-auto">
            <div className="w-full h-fit min-h-full flex flex-col justify-start items-center bg-white-primary shadow-md p-5 rounded-md gap-2">
                <div className="lg:w-[700px] w-full h-full flex flex-col justify-start items-center relative xl:p-3 p-0 rounded-md xl:shadow-md shadow-none shadow-white-tertiary">
                    <span className="w-full text-left font-semibold text-lg">
                        {t("company.avatar")}
                    </span>
                    <div className="py-3 px-5 w-full h-fit flex flex-col justify-end items-center">
                        <FileUpload
                            currentImageUrl={currentUser.image.original}
                            onImageUpload={handleFileChange}
                        />
                    </div>

                    <span className="w-full text-left font-semibold text-lg">
                        {t("company.account_information")}
                    </span>
                    <div className="flex flex-col justify-center items-start w-full py-3 px-5 text-[15px]">
                        <span>{t("company.name")}</span>
                        <input
                            type="text"
                            placeholder={t("company.name")}
                            className="mt-1 mb-3 w-full border border-white-secondary focus:border-white-tertiary outline-none py-[7px] px-4 rounded-md shadow-sm"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />

                        <span>{t("company.email")}</span>
                        <input
                            type="text"
                            disabled
                            placeholder={t("company.email")}
                            value={currentUser.email}
                            className="mt-1 mb-3 w-full border border-white-secondary focus:border-white-tertiary outline-none py-[7px] px-4 rounded-md shadow-sm text-white-tertiary bg-white-secondary"
                        />

                        <span>{t("company.phone_number")}</span>
                        <input
                            type="text"
                            placeholder={t("company.phone_number")}
                            className="mt-1 mb-3 w-full border border-white-secondary focus:border-white-tertiary outline-none py-[7px] px-4 rounded-md shadow-sm"
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                        />

                        <span
                            className={`w-full text-left font-semibold text-lg absolute left-3 ${
                                isChangePassword ? "scale-100" : "scale-0"
                            }`}>
                            {t("company.change_password")}
                        </span>
                        <div className="w-full h-fit flex flex-row justify-between items-center">
                            <span
                                className={`w-fit h-fit py-3 px-5 bg-primary text-white font-semibold rounded-md shadow-md hover:bg-primary-hover cursor-pointer mt-1 mb-3 transition truncate ${
                                    !isChangePassword ? "scale-100" : "scale-0"
                                }`}
                                onClick={() => setIsChangePassword(true)}>
                                {t("company.change_password")}
                            </span>
                            <span
                                className={`sm:w-fit w-[100px] h-fit py-3 px-5 bg-[#707070] text-white font-semibold rounded-md shadow-md hover:bg-black-secondary cursor-pointer mt-1 mb-3 transition truncate ${
                                    isChangePassword ? "scale-100" : "scale-0"
                                }`}
                                onClick={() => setIsChangePassword(false)}>
                                {t("company.close_changes")}
                            </span>
                        </div>
                        {isChangePassword && (
                            <div>
                                <span>{t("company.current_password")}</span>
                                <input
                                    type="password"
                                    placeholder={t("company.current_password")}
                                    className="mt-1 mb-3 w-full border border-white-secondary focus:border-white-tertiary outline-none py-[7px] px-4 rounded-md shadow-sm"
                                    onChange={(e) => setCurrentPassword(e.target.value)}
                                />

                                <span>{t("company.new_password")}</span>
                                <input
                                    type="password"
                                    placeholder={t("company.new_password")}
                                    className="mt-1 mb-3 w-full border border-white-secondary focus:border-white-tertiary outline-none py-[7px] px-4 rounded-md shadow-sm"
                                    onChange={(e) => setNewPassword(e.target.value)}
                                />

                                <span>{t("company.confirm_password")}</span>
                                <input
                                    type="password"
                                    placeholder={t("company.confirm_password")}
                                    className="mt-1 mb-3 w-full border border-white-secondary focus:border-white-tertiary outline-none py-[7px] px-4 rounded-md shadow-sm"
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                />
                            </div>
                        )}
                    </div>
                    <span
                        className="w-fit h-fit py-3 px-5 bg-primary text-white font-semibold rounded-md shadow-md hover:bg-primary-hover cursor-pointer mt-5 mb-10"
                        onClick={handleOnClick}>
                        {t("company.save_changes")}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default Profile;

import React, { useState, useRef, useEffect } from "react";
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Toolbar,
    TextField,
    Button,
    InputAdornment,
    Pagination,
    Checkbox,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { axiosWithJWT } from "../../config/axiosConfig";
import debounce from "lodash.debounce";
import { updateUserInformation } from "../../api/userApi";
import { toast } from "react-toastify";
import { confirmAlert } from "../react-confirm-alert/lib";

const StudentListTable = () => {
    const { t } = useTranslation();
    const [students, setStudents] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [page, setPage] = useState(1);
    const [showClearIcon, setShowClearIcon] = useState("none");
    const searchInputRef = useRef(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [selected, setSelected] = useState([]);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [selectedStudent, setSelectedStudent] = useState(null);

    useEffect(() => {
        const fetchStudents = async () => {
            try {
                const response = await axiosWithJWT.get(
                    `/api/users/listImport?page=${page}&pageSize=10&search=${searchTerm}`
                );
                setStudents(response.data.students);
                setTotalPages(response.data.totalPages);
                setSelected([]);
            } catch (error) {
                console.error("Error fetching students:", error);
            }
        };

        fetchStudents();
    }, [page, searchTerm]);

    const debouncedSearch = useRef(
        debounce((value) => {
            setSearchTerm(value);
        }, 300)
    ).current;

    const handleChange = (event) => {
        const value = event.target.value;
        setShowClearIcon(value === "" ? "none" : "flex");
        debouncedSearch(value);
    };

    const handleClick = () => {
        if (searchInputRef.current) {
            searchInputRef.current.value = "";
            setSearchTerm("");
            setShowClearIcon("none");
        }
    };

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = students.map((student) => student.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClickCheckbox = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        setSelected(newSelected);
    };

    const handleEditClick = (student) => {
        setSelectedStudent(student);
        setOpenEditModal(true);
    };

    const handleModalClose = () => {
        setOpenEditModal(false);
        setSelectedStudent(null);
    };

    const handleFieldChange = (field, value) => {
        setSelectedStudent((prev) => {
            const updatedStudent = { ...prev };
            if (field === "name" || field === "email" || field === "organizationId") {
                updatedStudent[field] = value;
            }
            return updatedStudent;
        });
    };

    const handleModalSave = async () => {
        if (selectedStudent) {
            const { name, email, organizationId } = selectedStudent;

            const updatedData = {
                username: organizationId,
                name,
                email,
                organizationId,
            };

            try {
                await updateUserInformation(updatedData, selectedStudent.id);
                setStudents((prevStudents) =>
                    prevStudents.map((student) =>
                        student.id === selectedStudent.id ? { ...student, ...updatedData } : student
                    )
                );
                toast.success(t("notify.update_success"));
                setSelected([]);
            } catch (error) {
                toast.error(
                    "Error updating student: " + (error.response?.data?.message || error.message)
                );
            }
        }
        handleModalClose();
    };

    const handleDelete = async () => {
        const deletePromises = selected.map(async (studentId) => {
            try {
                await axiosWithJWT.put(`/api/users/deleteStudent/` + studentId);
                return studentId;
            } catch (error) {
                toast.error(
                    "Error deleting student: " + (error.response?.data?.message || error.message)
                );
                return null;
            }
        });

        const deletedIds = await Promise.all(deletePromises);
        setStudents((prevStudents) =>
            prevStudents.filter((student) => !deletedIds.includes(student.id))
        );

        toast.success(t("notify.delete_success"));
        setSelected([]);
    };

    const confirmDelete = () => {
        confirmAlert({
            title: "",
            message: t("notify.are_you_sure_you_want_to_delete_this_student"),
            buttons: [
                {
                    label: "Yes",
                    onClick: handleDelete,
                },
                {
                    label: "No",
                    onClick: () => {},
                },
            ],
        });
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    return (
        <Box sx={{ mx: "auto" }}>
            <Toolbar
                sx={{
                    pl: { sm: 2 },
                    pr: { xs: 1, sm: 1 },
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}>
                <TextField
                    placeholder={t("university.search_student")}
                    variant="standard"
                    onChange={handleChange}
                    size="small"
                    sx={{
                        width: "300px",
                        "& .MuiInput-underline:before": {
                            borderBottom: "1px solid gray",
                        },
                        "& .MuiInput-underline:after": {
                            borderBottom: "2px solid #0a426e",
                        },
                    }}
                    inputRef={searchInputRef}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                <Button
                                    style={{ display: showClearIcon, padding: 0 }}
                                    onClick={handleClick}
                                    aria-label="Clear search">
                                    <ClearIcon style={{ cursor: "pointer" }} />
                                </Button>
                            </InputAdornment>
                        ),
                    }}
                />
                <Button
                    variant="outlined"
                    sx={{ mr: "10px", textTransform: "none" }}
                    color="error"
                    disabled={selected.length === 0}
                    onClick={confirmDelete}>
                    <DeleteIcon />
                    {t("university.delete")}
                </Button>
            </Toolbar>
            <TableContainer>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
                    <TableHead>
                        <TableRow>
                            {[
                                "index",
                                "student_name",
                                "email",
                                "organizationID",
                                "status",
                                "company",
                                "action",
                            ].map((header, index) => (
                                <TableCell
                                    key={index}
                                    component="th"
                                    scope="row"
                                    padding="none"
                                    sx={{
                                        height: 45,
                                        fontWeight: "bold",
                                        textAlign: "center",
                                        border: "1px solid rgba(224, 224, 224, 1)",
                                    }}>
                                    {index === 0 ? (
                                        <Checkbox
                                            color="primary"
                                            indeterminate={
                                                selected.length > 0 &&
                                                selected.length < students.length
                                            }
                                            checked={
                                                students.length > 0 &&
                                                selected.length === students.length
                                            }
                                            onChange={handleSelectAllClick}
                                        />
                                    ) : (
                                        t(`university.${header}`)
                                    )}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {students.map((student, index) => {
                            const isItemSelected = isSelected(student.id);
                            return (
                                <TableRow
                                    key={student.id}
                                    hover
                                    role="checkbox"
                                    aria-checked={isItemSelected}
                                    selected={isItemSelected}
                                    sx={{
                                        backgroundColor: isItemSelected
                                            ? "rgba(10, 66, 110, 0.1)" // Light blue background
                                            : "inherit",
                                    }}>
                                    <TableCell
                                        component="th"
                                        scope="row"
                                        sx={{
                                            width: 50,
                                            border: "1px solid rgba(224, 224, 224, 1)",
                                            textAlign: "center",
                                        }}>
                                        <Checkbox
                                            color="primary"
                                            checked={isItemSelected}
                                            onChange={(event) =>
                                                handleClickCheckbox(event, student.id)
                                            }
                                        />
                                    </TableCell>
                                    <TableCell
                                        component="th"
                                        scope="row"
                                        sx={{ border: "1px solid rgba(224, 224, 224, 1)" }}>
                                        {student.name}
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                        sx={{ border: "1px solid rgba(224, 224, 224, 1)" }}>
                                        {student.email}
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                        sx={{ border: "1px solid rgba(224, 224, 224, 1)" }}>
                                        {student.organizationId}
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                        sx={{ border: "1px solid rgba(224, 224, 224, 1)" }}>
                                        {student.isAccepted
                                            ? t("university.accepted_intern")
                                            : t("university.not_accepted_intern")}
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                        sx={{ border: "1px solid rgba(224, 224, 224, 1)" }}>
                                        {student.company || ""}
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                        sx={{ border: "1px solid rgba(224, 224, 224, 1)" }}>
                                        <div className="flex justify-center space-x-4">
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                size="small"
                                                sx={{
                                                    textTransform: "none",
                                                    backgroundColor: "#0a426e",
                                                    padding: "5px 20px",
                                                }}
                                                onClick={() => handleEditClick(student)}
                                                startIcon={<EditIcon />}>
                                                {t("university.edit")}
                                            </Button>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box sx={{ display: "flex", justifyContent: "flex-end", p: 2 }}>
                <Pagination
                    count={totalPages}
                    page={page}
                    variant="outlined"
                    onChange={handlePageChange}
                    color="primary"
                    sx={{
                        "& .MuiPaginationItem-root": {
                            margin: "0",
                            padding: "0",
                        },
                    }}
                    shape="rounded"
                />
            </Box>
            <Dialog open={openEditModal} onClose={handleModalClose}>
                <DialogTitle>{t("university.edit_student")}</DialogTitle>
                <DialogContent>
                    {selectedStudent && (
                        <>
                            <TextField
                                label="Organization ID"
                                fullWidth
                                margin="dense"
                                value={selectedStudent.organizationId}
                                onChange={(e) =>
                                    handleFieldChange("organizationId", e.target.value)
                                }
                            />
                            <TextField
                                label="Name"
                                fullWidth
                                margin="dense"
                                value={selectedStudent.name}
                                onChange={(e) => handleFieldChange("name", e.target.value)}
                            />
                            <TextField
                                label="Email"
                                fullWidth
                                margin="dense"
                                value={selectedStudent.email}
                                onChange={(e) => handleFieldChange("email", e.target.value)}
                            />
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleModalClose}
                        variant="outlined"
                        sx={{ mr: "5px", borderColor: "#0a426e", color: "#0a426e" }}>
                        {t("university.cancel")}
                    </Button>
                    <Button
                        variant="contained"
                        onClick={handleModalSave}
                        sx={{
                            borderColor: "#0a426e",
                            color: "#ffffff",
                            backgroundColor: "#0a426e",
                        }}>
                        {t("university.save")}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default StudentListTable;

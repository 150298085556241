import React, { useState, useEffect, useRef, useMemo, memo } from "react";
import convert from "color-convert";
import { Movable } from "webrix/components";
import { useDimensions } from "webrix/hooks";
import styles from "./custom.module.css";
import { useTranslation } from "react-i18next";
import FileUpload from "../upload/image";
import { uploadFile } from "../../api/uploadFile";
import { updateBrand } from "../../api/brandApi";
import { confirmAlert } from "../react-confirm-alert/lib";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const { transform, trackpad, update } = Movable.Operations;
const { clamp, map } = Movable.Transformers;

const HueSelector = memo(({ hsv, onChange }) => {
  const movable = useRef();
  const { width } = useDimensions(movable);
  const [left, setLeft] = useState(0);

  const props = Movable.useMove(
    useMemo(
      () => [
        trackpad(movable),
        transform((v) => v.left, clamp(0, width)),
        update((next) => {
          setLeft(next);
          onChange(
            convert.hsv.rgb(map(0, width, 0, 360)(next), hsv[1], hsv[2])
          );
        }),
      ],
      [onChange, width, hsv]
    )
  );

  useEffect(() => {
    setLeft(map(0, 360, 0, width)(hsv[0]));
  }, [width, hsv]);

  return (
    <Movable className={styles.hueSelector} ref={movable} {...props}>
      <div
        className={styles.pointer}
        style={{
          left,
          "--color": `rgb(${convert.hsv.rgb(
            map(0, width, 0, 360)(left),
            100,
            100
          )})`,
        }}
      />
    </Movable>
  );
});

const ShadeSelector = memo(({ onChange, hsv }) => {
  const movable = useRef();
  const { width, height } = useDimensions(movable);
  const hex = useMemo(() => "#" + convert.hsv.hex(hsv[0], 100, 100), [hsv[0]]);
  const [{ top, left }, setPosition] = useState({});

  const props = Movable.useMove(
    useMemo(
      () => [
        trackpad(movable),
        transform(({ top, left }) => ({
          top: clamp(0, height)(top),
          left: clamp(0, width)(left),
        })),
        update(({ top, left }) => {
          setPosition({ top, left });
          onChange(
            convert.hsv.rgb(
              hsv[0],
              map(0, width, 0, 100)(left),
              map(0, height, 100, 0)(top)
            )
          );
        }),
      ],
      [onChange, width, height, setPosition, hsv]
    )
  );

  useEffect(() => {
    setPosition({
      top: map(100, 0, 0, height)(hsv[2]),
      left: map(0, 100, 0, width)(hsv[1]),
    });
  }, [width, height, hsv]);

  return (
    <Movable
      className={styles.shadeSelector}
      ref={movable}
      style={{ "--color": hex }}
      {...props}
    >
      <div
        className={styles.pointer}
        style={{
          top,
          left,
          "--color": `rgb(${convert.hsv.rgb(hsv)})`,
        }}
      />
    </Movable>
  );
});

const InfoBox = ({ rgb, onChange }) => {
  const [hex, setHex] = useState(convert.rgb.hex(rgb));
  const [r, setR] = useState(rgb[0]);
  const [g, setG] = useState(rgb[1]);
  const [b, setB] = useState(rgb[2]);
  useEffect(() => {
    setR(rgb[0]);
    setG(rgb[1]);
    setB(rgb[2]);
  }, [rgb]);

  const handleColorChange = (value, type) => {
    if (type === "hex") {
      const hexValue = value.startsWith("#") ? value.slice(1) : value;
      setHex(hexValue);
      const rgb = convert.hex.rgb(hexValue);
      setR(rgb[0]);
      setG(rgb[1]);
      setB(rgb[2]);
      onChange(rgb);
      return;
    }

    let newRgb = [...rgb];
    if (type === "r") {
      newRgb[0] = Math.min(Math.max(Number(value), 0), 255);
      setR(newRgb[0]);
    }
    if (type === "g") {
      newRgb[1] = Math.min(Math.max(Number(value), 0), 255);
      setG(newRgb[1]);
    }
    if (type === "b") {
      newRgb[2] = Math.min(Math.max(Number(value), 0), 255);
      setB(newRgb[2]);
    }

    setHex(convert.rgb.hex(newRgb));
    onChange(newRgb);
  };

  return (
    <div className={styles.infoBox}>
      <div title="Hex">
        <input
          className="text-black-primary w-[70px] text-[12px] text-center outline-none"
          type="text"
          value={"#" + hex}
          onChange={(e) => handleColorChange(e.target.value, "hex")}
        />
      </div>
      <div title="R">
        <input
          type="text"
          className="text-black-primary w-[45px] text-[12px] text-center outline-none"
          value={r}
          onChange={(e) => handleColorChange(e.target.value, "r")}
        />
      </div>
      <div title="G">
        <input
          type="text"
          className="text-black-primary w-[45px] text-[12px] text-center outline-none"
          value={g}
          onChange={(e) => handleColorChange(e.target.value, "g")}
        />
      </div>
      <div title="B">
        <input
          type="text"
          className="text-black-primary w-[45px] text-[12px] text-center outline-none"
          value={b}
          onChange={(e) => handleColorChange(e.target.value, "b")}
        />
      </div>
    </div>
  );
};

const ColorPicker = ({ color, onChange }) => {
  const hsv = convert.rgb.hsv(color);
  return (
    <div className={styles.colorPicker}>
      <ShadeSelector hsv={hsv} onChange={onChange} />
      <HueSelector hsv={hsv} onChange={onChange} />
      <InfoBox rgb={color} onChange={onChange} />
    </div>
  );
};

const CustomColor = ({ color = [250, 250, 250], setColor }) => {
  return <ColorPicker color={color} onChange={setColor} />;
};

const Custom = () => {
  const { t } = useTranslation();
  const theme = useSelector((state) => state.themeReducer);

  // const brand = {
  //   id: 1,
  //   css: {
  //     primaryColor: "#FAFAFA",
  //     primaryColorRGB: [250, 250, 250],
  //     headerTextColor: "#000000",
  //     headerTextColorRGB: [0, 0, 0],
  //     navigateBackgroundColor: "#FFFFFF",
  //     navigateBackgroundColorRGB: [255, 255, 255],
  //     navigateTextColor: "#000000",
  //     navigateTextColorRGB: [0, 0, 0],
  //     loginBackgroundColor: "#FFFFFF",
  //     loginBackgroundColorRGB: [255, 255, 255],
  //     loginTextColor: "#000000",
  //     loginTextColorRGB: [0, 0, 0],
  //   },
  //   logo: "https://www.example.com/logo.png",
  //   loginBackground: "https://www.example.com/login-background.png",
  //   websiteName: "FIT",
  // };

  const [primaryColor, setPrimaryColor] = useState(theme.css.primaryColor);
  const [primaryColorRGB, setPrimaryColorRGB] = useState(
    theme.css.primaryColorRGB
  );
  const [headerTextColor, setHeaderTextColor] = useState(
    theme.css.headerTextColor
  );
  const [headerTextColorRGB, setHeaderTextColorRGB] = useState(
    theme.css.headerTextColorRGB
  );
  const [navigateBackgroundColor, setNavigateBackgroundColor] = useState(
    theme.css.navigateBackgroundColor
  );
  const [navigateBackgroundColorRGB, setNavigateBackgroundColorRGB] = useState(
    theme.css.navigateBackgroundColorRGB
  );
  const [navigateTextColor, setNavigateTextColor] = useState(
    theme.css.navigateTextColor
  );
  const [navigateTextColorRGB, setNavigateTextColorRGB] = useState(
    theme.css.navigateTextColorRGB
  );
  const [loginBackgroundColor, setLoginBackgroundColor] = useState(
    theme.css.loginBackgroundColor
  );
  const [loginBackgroundColorRGB, setLoginBackgroundColorRGB] = useState(
    theme.css.loginBackgroundColorRGB
  );
  const [loginTextColor, setLoginTextColor] = useState(
    theme.css.loginTextColor
  );
  const [loginTextColorRGB, setLoginTextColorRGB] = useState(
    theme.css.loginTextColorRGB
  );
  const [logoImage, setLogoImage] = useState(null);
  const [loginBackgroundImage, setLoginBackgroundImage] = useState(null);
  const [websiteName, setWebsiteName] = useState(theme.websiteName);

  const updatePrimaryColor = (color) => {
    setPrimaryColor(`#${convert.rgb.hex(color)}`);
    setPrimaryColorRGB(color);
  };

  const updateHeaderTextColor = (color) => {
    setHeaderTextColor(`#${convert.rgb.hex(color)}`);
    setHeaderTextColorRGB(color);
  };

  const updateNavigateBackgroundColor = (color) => {
    setNavigateBackgroundColor(`#${convert.rgb.hex(color)}`);
    setNavigateBackgroundColorRGB(color);
  };

  const updateNavigateTextColor = (color) => {
    setNavigateTextColor(`#${convert.rgb.hex(color)}`);
    setNavigateTextColorRGB(color);
  };

  const updateLoginBackgroundColor = (color) => {
    setLoginBackgroundColor(`#${convert.rgb.hex(color)}`);
    setLoginBackgroundColorRGB(color);
  };

  const updateLoginTextColor = (color) => {
    setLoginTextColor(`#${convert.rgb.hex(color)}`);
    setLoginTextColorRGB(color);
  };

  const handleSaveChanges = async () => {
    try {
      if (logoImage) {
        await uploadFile("api::brand.brand", theme.id, "logo", logoImage);
      }
      if (loginBackgroundImage) {
        await uploadFile(
          "api::brand.brand",
          theme.id,
          "background",
          loginBackgroundImage
        );
      }

      const data = {
        data: {
          css: {
            primaryColor: primaryColor,
            primaryColorRGB: primaryColorRGB,
            headerTextColor: headerTextColor,
            headerTextColorRGB: headerTextColorRGB,
            navigateBackgroundColor: navigateBackgroundColor,
            navigateBackgroundColorRGB: navigateBackgroundColorRGB,
            navigateTextColor: navigateTextColor,
            navigateTextColorRGB: navigateTextColorRGB,
            loginBackgroundColor: loginBackgroundColor,
            loginBackgroundColorRGB: loginBackgroundColorRGB,
            loginTextColor: loginTextColor,
            loginTextColorRGB: loginTextColorRGB,
          },
          websiteName: websiteName,
        },
      };

      await updateBrand(theme.id, data);
      toast.success(t("notify.update_success"));
      window.location.reload();
    } catch (error) {
      toast.error(t("notify.update_failed"));
    }
  };

  return (
    <div className="w-full flex-grow bg-black-tertiary p-3 overflow-auto">
      <div className="w-full h-fit min-h-full flex flex-col justify-start items-center bg-white-primary shadow-md p-5 rounded-md gap-2">
        <div className="lg:w-[700px] w-full h-full flex flex-col justify-start items-center relative xl:p-3 p-0 rounded-md xl:shadow-md shadow-none shadow-white-tertiary">
          <span className="w-full text-left font-semibold text-lg">
            {t("university.logo_image")}
          </span>
          <span className="w-full text-left text-sm italic px-4 text-black-secondary">
            {t("university.logo_note")}
          </span>
          <div className="py-7 px-5 w-full h-fit flex flex-col justify-end items-center">
            <FileUpload
              currentImageUrl={theme.logoUrl}
              onImageUpload={setLogoImage}
            />
          </div>
          <span className="w-full text-left font-semibold text-lg">
            {t("university.login_background_image")}
          </span>
          <span className="w-full text-left text-sm italic px-4 text-black-secondary">
            {t("university.login_background_image_note")}
          </span>
          <div className="py-7 px-5 w-full h-fit flex flex-col justify-end items-center">
            <FileUpload
              currentImageUrl={theme.backgroundUrl}
              onImageUpload={setLoginBackgroundImage}
            />
          </div>

          <span className="w-full text-left font-semibold text-lg mb-1">
            {t("university.custom_color")}
          </span>
          <div className="w-full h-fit flex flex-col justify-center items-start">
            <div className="w-full h-fit flex flex-col justify-center items-start">
              <span className="w-full text-left text-sm pl-3">
                {t("university.primary_color")}
              </span>
              <div className="w-full h-fit flex lg:flex-row flex-col lg:justify-around items-center mt-3 mb-5 gap-3">
                <div className="w-[280px] h-[356px] border-[1px] border-white-secondary py-4 rounded-md flex flex-col justify-between items-center">
                  <div
                    className="w-[250px] h-[250px] rounded-sm"
                    style={{ backgroundColor: primaryColor }}
                  ></div>
                  <span className="w-full text-center text-sm">
                    {t("university.primary_color")}
                  </span>
                  <span className="border-[1px] border-white-secondary px-5 py-1 rounded-md text-[13px]">
                    {primaryColor}
                  </span>
                </div>
                <CustomColor
                  color={primaryColorRGB}
                  setColor={updatePrimaryColor}
                />
              </div>
            </div>
            <div className="w-full h-fit flex flex-col justify-center items-start">
              <span className="w-full text-left text-sm pl-3">
                {t("university.header_text_color")}
              </span>
              <div className="w-full h-fit flex lg:flex-row flex-col lg:justify-around items-center mt-3 mb-5 gap-3">
                <div className="w-[280px] h-[356px] border-[1px] border-white-secondary py-4 rounded-md flex flex-col justify-between items-center">
                  <div
                    className="w-[250px] h-[250px] rounded-sm"
                    style={{ backgroundColor: headerTextColor }}
                  ></div>
                  <span className="w-full text-center text-sm">
                    {t("university.header_text_color")}
                  </span>
                  <span className="border-[1px] border-white-secondary px-5 py-1 rounded-md text-[13px]">
                    {headerTextColor}
                  </span>
                </div>
                <CustomColor
                  color={headerTextColorRGB}
                  setColor={updateHeaderTextColor}
                />
              </div>
            </div>
            <div className="w-full h-fit flex flex-col justify-center items-start">
              <span className="w-full text-left text-sm pl-3">
                {t("university.navigate_background_color")}
              </span>
              <div className="w-full h-fit flex lg:flex-row flex-col lg:justify-around items-center mt-3 mb-5 gap-3">
                <div className="w-[280px] h-[356px] border-[1px] border-white-secondary py-4 rounded-md flex flex-col justify-between items-center">
                  <div
                    className="w-[250px] h-[250px] rounded-sm"
                    style={{ backgroundColor: navigateBackgroundColor }}
                  ></div>
                  <span className="w-full text-center text-sm">
                    {t("university.navigate_background_color")}
                  </span>
                  <span className="border-[1px] border-white-secondary px-5 py-1 rounded-md text-[13px]">
                    {navigateBackgroundColor}
                  </span>
                </div>
                <CustomColor
                  color={navigateBackgroundColorRGB}
                  setColor={updateNavigateBackgroundColor}
                />
              </div>
            </div>
            <div className="w-full h-fit flex flex-col justify-center items-start">
              <span className="w-full text-left text-sm pl-3">
                {t("university.navigate_text_color")}
              </span>
              <div className="w-full h-fit flex lg:flex-row flex-col lg:justify-around items-center mt-3 mb-5 gap-3">
                <div className="w-[280px] h-[356px] border-[1px] border-white-secondary py-4 rounded-md flex flex-col justify-between items-center">
                  <div
                    className="w-[250px] h-[250px] rounded-sm"
                    style={{ backgroundColor: navigateTextColor }}
                  ></div>
                  <span className="w-full text-center text-sm">
                    {t("university.navigate_text_color")}
                  </span>
                  <span className="border-[1px] border-white-secondary px-5 py-1 rounded-md text-[13px]">
                    {navigateTextColor}
                  </span>
                </div>
                <CustomColor
                  color={navigateTextColorRGB}
                  setColor={updateNavigateTextColor}
                />
              </div>
            </div>
            <div className="w-full h-fit flex flex-col justify-center items-start">
              <span className="w-full text-left text-sm pl-3">
                {t("university.login_background_color")}
              </span>
              <div className="w-full h-fit flex lg:flex-row flex-col lg:justify-around items-center mt-3 mb-5 gap-3">
                <div className="w-[280px] h-[356px] border-[1px] border-white-secondary py-4 rounded-md flex flex-col justify-between items-center">
                  <div
                    className="w-[250px] h-[250px] rounded-sm"
                    style={{ backgroundColor: loginBackgroundColor }}
                  ></div>
                  <span className="w-full text-center text-sm">
                    {t("university.login_background_color")}
                  </span>
                  <span className="border-[1px] border-white-secondary px-5 py-1 rounded-md text-[13px]">
                    {loginBackgroundColor}
                  </span>
                </div>
                <CustomColor
                  color={loginBackgroundColorRGB}
                  setColor={updateLoginBackgroundColor}
                />
              </div>
            </div>
            <div className="w-full h-fit flex flex-col justify-center items-start">
              <span className="w-full text-left text-sm pl-3">
                {t("university.login_text_color")}
              </span>
              <div className="w-full h-fit flex lg:flex-row flex-col lg:justify-around items-center mt-3 mb-5 gap-3">
                <div className="w-[280px] h-[356px] border-[1px] border-white-secondary py-4 rounded-md flex flex-col justify-between items-center">
                  <div
                    className="w-[250px] h-[250px] rounded-sm"
                    style={{ backgroundColor: loginTextColor }}
                  ></div>
                  <span className="w-full text-center text-sm">
                    {t("university.login_text_color")}
                  </span>
                  <span className="border-[1px] border-white-secondary px-5 py-1 rounded-md text-[13px]">
                    {loginTextColor}
                  </span>
                </div>
                <CustomColor
                  color={loginTextColorRGB}
                  setColor={updateLoginTextColor}
                />
              </div>
            </div>
          </div>

          <span className="w-full text-left font-semibold text-lg">
            {t("university.website_information")}
          </span>
          <div className="flex flex-col justify-center items-start w-full py-3 px-5 text-[15px]">
            <span>{t("university.website_name")}</span>
            <input
              type="text"
              placeholder={t("university.website_name")}
              className="mt-1 mb-3 w-full border border-white-secondary focus:border-white-tertiary outline-none py-[7px] px-4 rounded-md shadow-sm"
              value={websiteName}
              onChange={(e) => setWebsiteName(e.target.value)}
            />
          </div>
          <span
            className="w-fit h-fit py-3 px-5 bg-primary text-white font-semibold rounded-md shadow-md hover:bg-primary-hover cursor-pointer mt-5 mb-10"
            onClick={() => {
              confirmAlert({
                title: "",
                message: t("notify.are_you_sure_you_want_to_update"),
                buttons: [
                  {
                    label: t("notify.yes"),
                    onClick: () => {
                      handleSaveChanges();
                    },
                  },
                  {
                    label: t("notify.no"),
                    onClick: () => {},
                  },
                ],
              });
            }}
          >
            {t("university.save_changes")}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Custom;

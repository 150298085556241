import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";

const ImageDropzone = ({ currentImageUrl, onImageUpload }) => {
  const { t } = useTranslation();
  const [image, setImage] = useState(null);

  const onDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      const preview = URL.createObjectURL(file);

      setImage(
        Object.assign(file, {
          preview,
        })
      );

      if (onImageUpload) {
        onImageUpload(file);
      }
    },
    [onImageUpload]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "image/*",
    multiple: false,
  });

  return (
    <div className="w-full flex flex-col items-center" {...getRootProps()}>
      <input {...getInputProps()} />

      <div className="w-full h-[150px] flex justify-center items-center p-2 border-dashed border-[1px] border-black-secondary cursor-pointer">
        {image ? (
          <img
            src={image.preview}
            alt={image.name}
            className="w-full h-full object-contain"
          />
        ) : (
          <img
            src={
              currentImageUrl !== undefined && currentImageUrl !== ""
                ? currentImageUrl
                : 'no_image.png'
            }
            alt="logo"
            className="w-full h-full object-contain"
          />
        )}
      </div>
      {isDragActive ? (
        <span className="text-[12px] text-blue-primary">
          {t("notify.drag_image_here")}
        </span>
      ) : (
        <span className="text-[12px] text-blue-primary">
          {t("notify.click_or_drag_image_to_the_above_box")}
        </span>
      )}
    </div>
  );
};

export default ImageDropzone;

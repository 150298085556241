import { axiosWithJWT } from "../config/axiosConfig";

const createInternshipFile = async (file, accountId) => {
    try {
        const response = await axiosWithJWT.post('/api/internship-files/', {
            data: {
                name: file.name,
                accountId: accountId,
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
}

const removeInternshipFile = async (id) => {
    try {
        const response = await axiosWithJWT.delete('/api/internship-files/delete/' + id);
        return response.data;
    } catch (error) {
        throw error;
    }
}

export { createInternshipFile, removeInternshipFile };
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    id: 0,
    name: "",
    email: "",
    gender: "",
    image: {
        original: "",
        thumbnail: "",
        small: "",
        medium: "",
        large: "",
    },
    phoneNumber: "",
    organizationId: "",
    isAccepted: false,
    company: null,
    isAuthenticated: false,
    role: "",
    registerInternships: [],
};

const userInformationSlice = createSlice({
    name: "userInformation",
    initialState,
    reducers: {
        setUserInformation: (state, action) => {
            state.id = action.payload.id;
            state.name = action.payload.name;
            state.email = action.payload.email;
            state.gender = action.payload.gender;
            state.image = action.payload.image;
            state.phoneNumber = action.payload.phoneNumber;
            state.organizationId = action.payload.organizationId;
            state.isAccepted = action.payload.isAccepted;
            state.company = action.payload.company;
            state.isAuthenticated = true;
            state.role = action.payload.role ? action.payload.role : "";
            state.registerInternships = action.payload.registerInternships;
        },
        logoutUser: (state) => {
            state.id = 0;
            state.name = "";
            state.email = "";
            state.gender = "";
            state.image = {
                original: "",
                thumbnail: "",
                small: "",
                medium: "",
                large: "",
            };
            state.phoneNumber = "";
            state.organizationId = "";
            state.isAccepted = false;
            state.company = null;
            state.isAuthenticated = false;
            state.role = "";
            state.registerInternships = [];
        },
    },
});

export const { setUserInformation, logoutUser } = userInformationSlice.actions;

export default userInformationSlice.reducer;
